import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import gold from "../../assets/gold.png";
import silver from "../../assets/silver.png";
import Web3 from "web3";
import Swal from "sweetalert2";
import {
	DOX_V1_CONTRACT_ADDRESS,
	DOX_V1_CONTRACT_ABI,
	DOX_GOLD_CONTRACT_ADDRESS,
	DOX_GOLD_CONTRACT_ABI,
	DOX_SILVER_CONTRACT_ADDRESS,
	DOX_SILVER_CONTRACT_ABI,
	DOX_BRONZE_CONTRACT_ADDRESS,
	DOX_BRONZE_CONTRACT_ABI,
	DOX_V1_DECIMALS,
} from "../../constants/constants";
// let web3 = new Web3(window.ethereum || "https://data-seed-prebsc-1-s1.binance.org:8545/");  //TESTNET
let web3 = new Web3(window.ethereum || "https://bsc-dataseed.binance.org/");   //MAINNET
/* global BigInt */

const Minting = ({ userAddress, connector, chain, show1, setShow1, disableSignup, setDisableSignup }) => {
	// 0 == gold
	const [gTier, setGoldTier] = useState({
		counter: 0,
		balance: 0,
		limit: 0,
		isApproved: false,
		totalSupply: 0,
		soldNFTs: 0,
	});
	// 1 == silver
	const [sTier, setSilverTier] = useState({
		counter: 0,
		balance: 0,
		limit: 0,
		isApproved: false,
		totalSupply: 0,
		soldNFTs: 0,
	});
	// 2 == bronze
	const [bTier, setBronzeTier] = useState({
		counter: 0,
		balance: 0,
		limit: 0,
		isApproved: false,
		totalSupply: 0,
		soldNFTs: 0,
	});

	const increment = (tierNum) => {
		// console.log(gTier);
		if (tierNum === 0) {
			if (gTier.counter < 10) {
				setGoldTier({ ...gTier, counter: gTier.counter + 1 });
			}
		} else if (tierNum === 1) {
			if (sTier.counter < 10) {
				setSilverTier({ ...sTier, counter: sTier.counter + 1 });
			}
		} else if (tierNum === 2) {
			if (bTier.counter < 10) {
				setBronzeTier({ ...bTier, counter: bTier.counter + 1 });
			}
		}
	};

	const decrement = (tierNum) => {
		if (tierNum === 0) {
			if (gTier.counter > 0) {
				setGoldTier({ ...gTier, counter: gTier.counter - 1 });
			}
		} else if (tierNum === 1) {
			if (sTier.counter > 0) {
				setSilverTier({ ...sTier, counter: sTier.counter - 1 });
			}
		} else if (tierNum === 2) {
			if (bTier.counter > 0) {
				setBronzeTier({ ...bTier, counter: bTier.counter - 1 });
			}
		}
	};

	const initWeb3 = (provider) => {
		const web3 = new Web3(provider);

		web3.eth.extend({
			methods: [
				{
					name: "chainId",
					call: "eth_chainId",
					outputFormatter: web3.utils.hexToNumber,
				},
			],
			headers: [
				{
					name: "Access-Control-Allow-Origin",
					value: "https://rinkeby.infura.io/v3/key",
				},
			],
		});
		return web3;
	};

	const initializeContract = async (abi, address) => {
		if (localStorage.getItem("walletconnect")) {
			const web3 = initWeb3(connector);
		}
		try {
			let res = new web3.eth.Contract(abi, address);
			return res;
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const setTier = async (tierNum) => {
		try {
			let balance = 0;
			let soldNFTs = 0;
			let totalSupply = 0;
			if (tierNum === 0) {
				const contract_instance = await initializeContract(DOX_GOLD_CONTRACT_ABI, DOX_GOLD_CONTRACT_ADDRESS);
				if (userAddress) {
					balance = await contract_instance.methods.balanceOf(userAddress).call();
				}
				soldNFTs = await contract_instance.methods.getSoldNFTs().call();
				totalSupply = await contract_instance.methods.totalSupply().call();
				setGoldTier({
					...gTier,
					counter: 0,
					balance: Number(balance),
					limit: 10 - Number(balance),
					soldNFTs: Number(soldNFTs),
					totalSupply: Number(totalSupply),
				});
				return {
					balance: Number(balance),
					limit: 10 - Number(balance),
					soldNFTs: Number(soldNFTs),
					totalSupply: Number(totalSupply),
				};
			} else if (tierNum === 1) {
				const contract_instance = await initializeContract(DOX_SILVER_CONTRACT_ABI, DOX_SILVER_CONTRACT_ADDRESS);
				if (userAddress) {
					balance = await contract_instance.methods.balanceOf(userAddress).call();
				}
				soldNFTs = await contract_instance.methods.getSoldNFTs().call();
				totalSupply = await contract_instance.methods.totalSupply().call();
				setSilverTier({
					...sTier,
					counter: 0,
					balance: Number(balance),
					limit: 10 - Number(balance),
					soldNFTs: Number(soldNFTs),
					totalSupply: Number(totalSupply),
				});
				return {
					balance: Number(balance),
					limit: 10 - Number(balance),
					soldNFTs: Number(soldNFTs),
					totalSupply: Number(totalSupply),
				};
			} else if (tierNum === 2) {
				const contract_instance = await initializeContract(DOX_BRONZE_CONTRACT_ABI, DOX_BRONZE_CONTRACT_ADDRESS);
				if (userAddress) {
					balance = await contract_instance.methods.balanceOf(userAddress).call();
				}
				soldNFTs = await contract_instance.methods.getSoldNFTs().call();
				totalSupply = await contract_instance.methods.totalSupply().call();
				setBronzeTier({
					...bTier,
					counter: 0,
					balance: Number(balance),
					limit: 10 - Number(balance),
					soldNFTs: Number(soldNFTs),
					totalSupply: Number(totalSupply),
				});
				return {
					balance: Number(balance),
					limit: 10 - Number(balance),
					soldNFTs: Number(soldNFTs),
					totalSupply: Number(totalSupply),
				};
			}
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const checkApproval = async (tierNum, tier) => {
		try {
			const contract_instance = await initializeContract(DOX_V1_CONTRACT_ABI, DOX_V1_CONTRACT_ADDRESS);
			let data = 0;
			if (tierNum === 0) {
				if (userAddress) {
					data = await contract_instance.methods.allowance(userAddress, DOX_GOLD_CONTRACT_ADDRESS).call();
				}
				const isApproved = Number(data) > 0 ? true : false;
				if (tier) {
					setGoldTier({ ...gTier, isApproved, ...tier });
				} else {
					setGoldTier({ ...gTier, isApproved });
				}
			} else if (tierNum === 1) {
				if (userAddress) {
					data = await contract_instance.methods.allowance(userAddress, DOX_SILVER_CONTRACT_ADDRESS).call();
				}
				const isApproved = Number(data) > 0 ? true : false;
				if (tier) {
					setSilverTier({ ...sTier, isApproved, ...tier });
				} else {
					setSilverTier({ ...sTier, isApproved });
				}
			} else if (tierNum === 2) {
				if (userAddress) {
					data = await contract_instance.methods.allowance(userAddress, DOX_BRONZE_CONTRACT_ADDRESS).call();
				}
				const isApproved = Number(data) > 0 ? true : false;
				if (tier) {
					setBronzeTier({ ...bTier, isApproved, ...tier });
				} else {
					setBronzeTier({ ...bTier, isApproved });
				}
			}
			return;
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const approveWalletConnect = async (tierNum) => {
		Swal.fire({
			title: "Approving you wallet...",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
		});
		try {
			let method;
			const contract_instance = await initializeContract(DOX_V1_CONTRACT_ABI, DOX_V1_CONTRACT_ADDRESS);
			const web3 = initWeb3(connector);
			if (tierNum === 0) {
				method = contract_instance.methods.approve(DOX_GOLD_CONTRACT_ADDRESS, BigInt(10000000000000 * DOX_V1_DECIMALS));
			} else if (tierNum === 1) {
				method = contract_instance.methods.approve(
					DOX_SILVER_CONTRACT_ADDRESS,
					BigInt(10000000000000 * DOX_V1_DECIMALS)
				);
			} else if (tierNum === 2) {
				method = contract_instance.methods.approve(
					DOX_BRONZE_CONTRACT_ADDRESS,
					BigInt(10000000000000 * DOX_V1_DECIMALS)
				);
			}
			const encodedABI = await method.encodeABI();
			// console.log("executing transaction");

			let transaction;
			setTimeout(() => {
				console.log("timer rejected transaction");
				if (!transaction) {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to approve!...",
					});
					return;
				}
			}, 20000);

			await web3.eth
				.sendTransaction({
					from: userAddress,
					to: DOX_V1_CONTRACT_ADDRESS,
					data: encodedABI,
				})
				.then((result) => {
					if (result) {
						transaction = result;
						Swal.close();
						Swal.fire({
							icon: "success",
							title: "Successfully approved!",
						});
						checkApproval(tierNum);
						return;
					}
				})
				.catch((error) => {
					console.log(error);
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to approve!...",
					});
					return;
				});

			// console.log("transaction completed");
		} catch (error) {
			console.log(error);
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to approve.Something unknown happened!",
			});
			return;
		}
	};

	const approveWallet = async (tierNum) => {
		if (localStorage.getItem("walletconnect")) {
			await approveWalletConnect(tierNum);
			return;
		} else {
			Swal.fire({
				title: "Approving you wallet...",
				html: "Please proceed your transaction through wallet!",
				didOpen: () => {
					Swal.showLoading();
				},
			});
			// console.log(tierNum);
			try {
				let data;
				const contract_instance = await initializeContract(DOX_V1_CONTRACT_ABI, DOX_V1_CONTRACT_ADDRESS);
				if (tierNum === 0) {
					data = await contract_instance.methods
						.approve(DOX_GOLD_CONTRACT_ADDRESS, BigInt(10000000000000 * DOX_V1_DECIMALS))
						.send({ from: userAddress });
					console.log(data, "data");
				} else if (tierNum === 1) {
					data = await contract_instance.methods
						.approve(DOX_SILVER_CONTRACT_ADDRESS, BigInt(10000000000000 * DOX_V1_DECIMALS))
						.send({ from: userAddress });
					console.log(data, "data");
				} else if (tierNum === 2) {
					data = await contract_instance.methods
						.approve(DOX_BRONZE_CONTRACT_ADDRESS, BigInt(10000000000000 * DOX_V1_DECIMALS))
						.send({ from: userAddress });
					console.log(data, "data");
				}
				if (data) {
					Swal.close();
					Swal.fire({
						icon: "success",
						title: "Successfully approved!",
					});
					checkApproval(tierNum);
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to approve!...",
					});
				}
			} catch (error) {
				console.log(error);
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Failed to approve.Something unknown happened!",
				});
				return;
			}
		}
	};

	const mintNFT_WC = async (tierNum) => {
		Swal.fire({
			title: "Minting...",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
		});
		try {
			let method;
			const web3 = initWeb3(connector);
			if (tierNum === 0) {
				const contract_instance = await initializeContract(DOX_GOLD_CONTRACT_ABI, DOX_GOLD_CONTRACT_ADDRESS);
				method = await contract_instance.methods.mintGoldDox(gTier.counter);
			} else if (tierNum === 1) {
				const contract_instance = await initializeContract(DOX_SILVER_CONTRACT_ABI, DOX_SILVER_CONTRACT_ADDRESS);
				method = await contract_instance.methods.mintSilverDox(sTier.counter);
			} else if (tierNum === 2) {
				const contract_instance = await initializeContract(DOX_BRONZE_CONTRACT_ABI, DOX_BRONZE_CONTRACT_ADDRESS);
				method = await contract_instance.methods.mintBronzeDox(bTier.counter);
			}
			const encodedABI = await method.encodeABI();

			let contractToCall =
				tierNum === 0
					? DOX_GOLD_CONTRACT_ADDRESS
					: tierNum === 1
					? DOX_SILVER_CONTRACT_ADDRESS
					: DOX_BRONZE_CONTRACT_ADDRESS;
			// console.log("executing transaction", contractToCall, tierNum);

			await web3.eth
				.sendTransaction({
					from: userAddress,
					to: contractToCall,
					data: encodedABI,
				})
				.then((result) => {
					console.log(result);
					if (result) {
						Swal.close();
						Swal.fire({
							icon: "success",
							title: "Successfully minted!",
						});
						setTier(tierNum);
						return;
					}
				})
				.catch((error) => {
					console.log(error);
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to mint...!",
					});
					return;
				});
			// console.log("transaction completed");
		} catch (error) {
			console.log(error);
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to mint.Something unknown happened!",
			});
			return;
		}
	};

	const mintNFT = async (tierNum) => {
		if (localStorage.getItem("walletconnect")) {
			await mintNFT_WC(tierNum);
			return;
		} else {
			Swal.fire({
				title: "Minting",
				html: "Please proceed your transaction through wallet!",
				didOpen: () => {
					Swal.showLoading();
				},
			});
			try {
				let data;
				if (tierNum === 0) {
					const contract_instance = await initializeContract(DOX_GOLD_CONTRACT_ABI, DOX_GOLD_CONTRACT_ADDRESS);
					data = await contract_instance.methods.mintGoldDox(gTier.counter).send({ from: userAddress });
					console.log(data, "data");
				} else if (tierNum === 1) {
					const contract_instance = await initializeContract(DOX_SILVER_CONTRACT_ABI, DOX_SILVER_CONTRACT_ADDRESS);
					data = await contract_instance.methods.mintSilverDox(sTier.counter).send({ from: userAddress });
					console.log(data, "data");
				} else if (tierNum === 2) {
					const contract_instance = await initializeContract(DOX_BRONZE_CONTRACT_ABI, DOX_BRONZE_CONTRACT_ADDRESS);
					data = await contract_instance.methods.mintBronzeDox(bTier.counter).send({ from: userAddress });
					console.log(data, "data");
				}
				if (data) {
					Swal.close();
					Swal.fire({
						icon: "success",
						title: "Successfully minted!",
					});
					setTier(tierNum);
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to mint!...",
					});
				}
			} catch (error) {
				console.log(error);
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Failed to mint.Something unknown happened!",
				});
				return;
			}
		}
	};

	useEffect(() => {
		Promise.all([setTier(0), setTier(1), setTier(2)])
			.then((tier) => {
				Promise.all([checkApproval(0, tier[0]), checkApproval(1, tier[1]), checkApproval(2, tier[2])])
					.then((data) => {
						// console.log(data);
					})
					.catch((er) => {
						console.log(er);
					});
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userAddress]);

	return (
		<div>
			<section class="section section-sm">
				<div class="container">
					<div class="w-full text-center mb-5">
						<h2 class="text-white fw-black u-upper">DOX NFTs</h2>
					</div>
					<div class="justify-content-center row">
						<div class="col-sm-12 col-md-12 col-lg-11">
							<div class="row">
								<div class="col-sm-12 col-md-4 col-lg-4">
									<div class="booster-box">
										<h2 class="text-white fw-black u-upper text-center mb-3">15 Billion $DOX V1</h2>
										<div class="booster-box-wrap booster-box-wrap-sm text-center">
											<h2 class="text-white fw-bold u-upper text-center mb-3">GOLD</h2>
											<div class="tier-options tier-options-sm mx-auto mb-4">
												<img src={gold} alt="GOLD" class="img-fluid" />
											</div>
											<div class="nft-spinner mb-3">
												<button type="button" class="btn-minus" onClick={() => decrement(0)}>
													-
												</button>
												<div class="nft-number">{gTier.counter}</div>
												<button type="button" class="btn-plus" onClick={() => increment(0)}>
													+
												</button>
											</div>
											<div class="w-full mt-1">
												{!gTier.isApproved && (
													<button
														// disabled={
														// 	userAddress && (gTier.counter > gTier.limit || gTier.limit === 0 || gTier.counter === 0)
														// }
														type="button"
														onClick={userAddress ? () => approveWallet(0) : () => setShow1(true)}
														class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap ">
														{userAddress ? "Approve" : "Connect Wallet"}
													</button>
												)}
												{gTier.isApproved && (
													<button
														disabled={
															userAddress && (gTier.counter > gTier.limit || gTier.limit === 0 || gTier.counter === 0)
														}
														type="button"
														onClick={userAddress ? () => mintNFT(0) : () => setShow1(true)}
														class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap ">
														{userAddress ? "Mint" : "Connect Wallet"}
													</button>
												)}
												<p class="fs-13 pt-2 mb-0">You've minted {gTier.balance} NFTs</p>
												<p class="fs-13 pt-2">10 max per wallet</p>
												<div class="total fs-30 fs-30-sm fw-black pt-4">
													{gTier.soldNFTs}/{gTier.totalSupply}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-4 col-lg-4">
									<div class="booster-box">
										<h2 class="text-white fw-black u-upper text-center mb-3">10 Billion $DOX V1</h2>
										<div class="booster-box-wrap booster-box-wrap-sm text-center">
											<h2 class="text-white fw-bold u-upper text-center mb-3">SILVER</h2>
											<div class="tier-options tier-options-sm mx-auto mb-4">
												<img src={silver} alt="SILVER" class="img-fluid" />
											</div>
											<div class="nft-spinner mb-3">
												<button type="button" class="btn-minus" onClick={() => decrement(1)}>
													-
												</button>
												<div class="nft-number">{sTier.counter}</div>
												<button type="button" class="btn-plus" onClick={() => increment(1)}>
													+
												</button>
											</div>
											<div class="w-full mt-1">
												{!sTier.isApproved && (
													<button
														// disabled={
														// 	userAddress && (sTier.counter > sTier.limit || sTier.limit === 0 || sTier.counter === 0)
														// }
														type="button"
														onClick={userAddress ? () => approveWallet(1) : () => setShow1(true)}
														class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap ">
														{userAddress ? "Approve" : "Connect Wallet"}
													</button>
												)}
												{sTier.isApproved && (
													<button
														disabled={
															userAddress && (sTier.counter > sTier.limit || sTier.limit === 0 || sTier.counter === 0)
														}
														type="button"
														onClick={userAddress ? () => mintNFT(1) : () => setShow1(true)}
														class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap ">
														{userAddress ? "Mint" : "Connect Wallet"}
													</button>
												)}
												<p class="fs-13 pt-2 mb-0">You've minted {sTier.balance} NFTs</p>
												<p class="fs-13 pt-2">10 max per wallet</p>
												<div class="total fs-30 fs-30-sm fw-black pt-4">
													{sTier.soldNFTs}/{sTier.totalSupply}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-4 col-lg-4">
									<div class="booster-box">
										<h2 class="text-white fw-black u-upper text-center mb-3">5 Billion $DOX V1</h2>
										<div class="booster-box-wrap booster-box-wrap-sm text-center">
											<h2 class="text-white fw-bold u-upper text-center mb-3">BRONZE</h2>
											<div class="tier-options tier-options-sm mx-auto mb-4">
												<img src={logo} alt="BRONZE" class="img-fluid" />
											</div>
											<div class="nft-spinner mb-3">
												<button type="button" class="btn-minus" onClick={() => decrement(2)}>
													-
												</button>
												<div class="nft-number">{bTier.counter}</div>
												<button type="button" class="btn-plus" onClick={() => increment(2)}>
													+
												</button>
											</div>
											<div class="w-full mt-1">
												{!bTier.isApproved && (
													<button
														// disabled={
														// 	userAddress && (bTier.counter > bTier.limit || bTier.limit === 0 || bTier.counter === 0)
														// }
														type="button"
														onClick={userAddress ? () => approveWallet(2) : () => setShow1(true)}
														class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap ">
														{userAddress ? "Approve" : "Connect Wallet"}
													</button>
												)}
												{bTier.isApproved && (
													<button
														disabled={
															userAddress && (bTier.counter > bTier.limit || bTier.limit === 0 || bTier.counter === 0)
														}
														type="button"
														onClick={userAddress ? () => mintNFT(2) : () => setShow1(true)}
														class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap ">
														{userAddress ? "Mint" : "Connect Wallet"}
													</button>
												)}
												<p class="fs-13 pt-2 mb-0">You've minted {bTier.balance} NFTs</p>
												<p class="fs-13 pt-2">10 max per wallet</p>
												<div class="total fs-30 fs-30-sm fw-black pt-4">
													{bTier.soldNFTs}/{bTier.totalSupply}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section section-sm sm:pt-0">
				<div class="container">
					<div class="w-full text-center mb-5">
						<h2 class="text-white fw-black u-upper">WHAT DO THEY DO?</h2>
					</div>
					<div class="justify-content-center row">
						<div class="col-sm-12 col-md-11 col-lg-10">
							<div class="row">
								<div class="col-sm-12 col-md-4 col-lg-4">
									<div class="booster-box">
										<div class="booster-box-wrap booster-box-wrap-sm text-center">
											<h2 class="text-white fw-bold u-upper text-center mb-3">GOLD</h2>
											<p class="off-white">
												This amazing DOX NFT will earn you rewards just for holding it!. Buy this NFT using your $DOX V1
												token. Stake your NFTs using the DOX Staking platform and earn 0.5$ every day.
											</p>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-4 col-lg-4">
									<div class="booster-box">
										<div class="booster-box-wrap booster-box-wrap-sm text-center">
											<h2 class="text-white fw-bold u-upper text-center mb-3">SILVER</h2>
											<p class="off-white">
												This amazing DOX NFT will earn you rewards just for holding it!. Buy this NFT using your $DOX V1
												token. Stake your NFTs using the DOX Staking platform and earn 0.25$ every day.
											</p>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-4 col-lg-4">
									<div class="booster-box">
										<div class="booster-box-wrap booster-box-wrap-sm text-center">
											<h2 class="text-white fw-bold u-upper text-center mb-3">BRONZE</h2>
											<p class="off-white">
												This amazing DOX NFT will earn you rewards just for holding it!. Buy this NFT using your $DOX V1
												token. Stake your NFTs using the DOX Staking platform and earn 0.1$ every day.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Minting;
