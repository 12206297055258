import React, { useState, useEffect } from "react";
import logo from "../../assets/logo2.png";
import metamask from "../../assets/images/metamask.svg";
import walletConnectLogo from "../../assets/walletConnect.svg";
import { Modal } from "react-bootstrap";
import WalletConnect from "@walletconnect/web3-provider";
import Web3 from "web3";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import "./Nav.css";
import { CHAIN_ID, HEX_CHAIN_ID } from "../../constants/constants.js";
import logout from "../../assets/images/logout.svg";
// let web3 = new Web3(window.ethereum || "https://data-seed-prebsc-1-s1.binance.org:8545/");   //TESTNET
let web3 = new Web3(window.ethereum || "https://bsc-dataseed.binance.org/");   //MAINNET

const NavBar = ({
	setUserAddress,
	userAddress,
	connector,
	setConnector,
	setChain,
	show1,
	setShow1,
	disableSignup,
	setDisableSignup,
}) => {
	let navigate = useNavigate();
	const openModal = () => setShow1(true);
	const closeModal = () => {
		setDisableSignup(false);
		setShow1(false);
	};

	const initWeb3 = (provider) => {
		const web3 = new Web3(provider);

		web3.eth.extend({
			methods: [
				{
					name: "chainId",
					call: "eth_chainId",
					outputFormatter: web3.utils.hexToNumber,
				},
			],
			headers: [
				{
					name: "Access-Control-Allow-Origin",
					value: "https://rinkeby.infura.io/v3/key",
				},
			],
		});
		return web3;
	};

	const provider = new WalletConnect({
		rpc: {
			// 1: "https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7",
			// 97: "https://data-seed-prebsc-1-s3.binance.org:8545/",   //TESTNET
			56: "https://bsc-dataseed.binance.org/",  //MAINNET
		},
		chainId: CHAIN_ID,
	});

	const walletConnect = async () => {
		setDisableSignup(true);
		try {
			await provider.enable();
			setDisableSignup(false);
			const web = initWeb3(provider);
			const accounts = await web.eth.getAccounts();
			const publicAddress = accounts[0].toLowerCase();
			setUserAddress(publicAddress);
			localStorage.setItem("userAddress", publicAddress);
			setConnector(provider);
			closeModal();
		} catch (error) {
			setDisableSignup(false);
		}
	};

	// Subscribe to chainId change
	provider.on("chainChanged", (chainId) => {
		Swal.close();
		setChain(chainId);
		if (chainId != CHAIN_ID) {
			Swal.fire({
				icon: "error",
				text: "Wrong Chain Selected, Please select BSC Testnet",
				position: "top-right",
				showCancelButton: false,
				showConfirmButton: false,
				allowOutsideClick: false,
			});
		}
	});

	// Subscribe to session disconnection
	provider.on("disconnect", (code, reason) => {
		console.log(code, reason);
		localStorage.removeItem("userAddress");
		localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
		localStorage.removeItem("walletconnect");
		setUserAddress("");
		setConnector("");
	});

	const metaMask = async () => {
		setDisableSignup(true);
		if (!window.ethereum) {
			window.alert("Please install MetaMask first.");
			setDisableSignup(false);
			return;
		}
		if (!web3) {
			try {
				await window.ethereum.enable();
				web3 = new Web3(window.ethereum);
			} catch (error) {
				window.alert("You need to allow MetaMask.");
				setDisableSignup(false);
				return;
			}
		}

		await window.ethereum.enable();
		let publicAddress = await web3.eth.getCoinbase();
		try {
			await web3.eth.personal.sign(`Connecting to DOX V2`, publicAddress, "");
			localStorage.setItem("userAddress", publicAddress);
			setUserAddress(publicAddress);
			closeModal();
		} catch (err) {
			setDisableSignup(false);
			throw new Error("You need to sign the message to be able to Sign Up.");
		}
	};

	const logout = async () => {
		navigate("/", { replace: true });
		setUserAddress("");
		localStorage.removeItem("userAddress");
		localStorage.removeItem("walletconnect");
	};

	useEffect(() => {
		if (window.ethereum) {
			window.ethereum.on("accountsChanged", () => {
				logout();
			});

			window.ethereum.on("chainChanged", (chainId) => {
				Swal.close();
				setChain(chainId);
				if (chainId != HEX_CHAIN_ID || chainId != CHAIN_ID) {
					Swal.fire({
						icon: "error",
						text: "Wrong Chain Selected, Please select Binance Smart Chain",
						position: "top-right",
						showCancelButton: false,
						showConfirmButton: false,
						allowOutsideClick: false,
					});
				}
			});

			let web3_instance = new Web3(window.ethereum);
			if (window.ethereum.isMetaMask) {
				web3_instance.eth.getChainId().then((chainId) => {
					setChain(chainId);
					if (chainId != CHAIN_ID) {
						Swal.fire({
							icon: "error",
							text: "Wrong Chain Selected, Please select Binance Smart Chain",
							position: "top-right",
							showCancelButton: false,
							showConfirmButton: false,
							allowOutsideClick: false,
						});
					}
				});
			} else {
				web3_instance.eth.net.getId().then((chainId) => {
					setChain(chainId);
					if (chainId != CHAIN_ID) {
						Swal.fire({
							icon: "error",
							text: "Wrong Chain Selected, Please select Binance Smart Chain",
							position: "top-right",
							showCancelButton: false,
							showConfirmButton: false,
							allowOutsideClick: false,
						});
					}
				});
			}
		}
		setUserAddress(localStorage.getItem("userAddress"));
	}, []);

	useEffect(() => {
		const a = async () => {
			await provider.enable();
			let web3 = initWeb3(provider);
			const accounts = await web3.eth.getAccounts();
			const address = accounts[0];
			localStorage.setItem("userAddress", address);
			setConnector(provider);
			setUserAddress(address);
		};
		if (localStorage.getItem("walletconnect") !== null) {
			a();
		}
	}, []);

	return (
		<>
			<header class="header header-sm" id="appHeader">
				<div class="p-0 container">
					<div class="header-main header-main-sm">
						<div class="d-flex align-items-center">
							<a href="/" class="header-main-logo header-main-logo-sm">
								<img src={logo} height={60} alt="Dox" class="img-fluid" />
							</a>
						</div>
						<div class="d-flex align-items-center gap-5">
							<div class="d-md-flex d-none align-items-center gap-5">
								<Link to="/" class="nav-links">
									HOME
								</Link>
								<Link to="/mint" class="nav-links ">
									MINT
								</Link>
								{/* <Link to="/stake" class="nav-links">
									STAKE
								</Link> */}
							</div>
							<nav class="navigation navigation-sm">
								<ul class="navigation-list">
									<li>
										{!userAddress && (
											<button type="button" class="webapp btn btn-secondary" onClick={() => openModal()}>
												<span class="size-24">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
														<path d="M27 5.7h-7.6C19 4.1 17.6 3 16 3c-1.6 0-3 1.1-3.4 2.7H5c-2.8 0-5 2.2-5 5V24c0 2.8 2.2 5 5 5h22c2.8 0 5-2.2 5-5V10.7c0-2.8-2.2-5-5-5zM16 4.6c1 0 1.9.8 1.9 1.9 0 .5-.2 1-.5 1.3-.7.7-1.9.7-2.7 0-.3-.3-.5-.8-.5-1.3-.1-1.1.8-1.9 1.8-1.9zm11 22.8H9.2v-.3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.3H6v-.3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.2C2.8 27 1.6 25.6 1.6 24V10.7c0-1.6 1.1-3 2.7-3.3v.2c0 .5.4.8.8.8.5 0 .9-.4.9-.8v-.3h1.6v.3c0 .4.4.8.8.8s.8-.4.8-.8v-.3h3.4c.3 1.2 1.2 2.2 2.5 2.6 1.2.3 2.5 0 3.4-.9.5-.5.8-1 .9-1.7H27c1.9 0 3.4 1.5 3.4 3.4v3.1h-6.2c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5h6.2V24c0 1.9-1.5 3.4-3.4 3.4zm3.4-8.2h-6.2c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9h6.2v3.8zm0 0"></path>
														<path d="M5.2 17.6c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.4-.4-.8-.8-.8zm0-4.3c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.4-.8-.8-.8zm0-4.4c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8V9.8c0-.5-.4-.9-.8-.9zm0 13.1c-.4 0-.8.4-.8.8V25c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.4-.8-.8-.8zM8.4 8.9c-.4 0-.8.4-.8.9V12c0 .4.4.8.8.8s.8-.4.8-.8V9.8c0-.5-.3-.9-.8-.9zm0 13.1c-.4 0-.8.4-.8.8V25c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.3-.8-.8-.8zm0-8.7c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.3-.8-.8-.8zm0 4.3c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.4-.3-.8-.8-.8zm0 0"></path>
													</svg>
												</span>
												Connect Wallet
											</button>
										)}
										{userAddress && (
											<button type="button" class="webapp btn btn-secondary" onClick={() => logout()}>
												<span class="size-24">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
														<path d="M27 5.7h-7.6C19 4.1 17.6 3 16 3c-1.6 0-3 1.1-3.4 2.7H5c-2.8 0-5 2.2-5 5V24c0 2.8 2.2 5 5 5h22c2.8 0 5-2.2 5-5V10.7c0-2.8-2.2-5-5-5zM16 4.6c1 0 1.9.8 1.9 1.9 0 .5-.2 1-.5 1.3-.7.7-1.9.7-2.7 0-.3-.3-.5-.8-.5-1.3-.1-1.1.8-1.9 1.8-1.9zm11 22.8H9.2v-.3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.3H6v-.3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.2C2.8 27 1.6 25.6 1.6 24V10.7c0-1.6 1.1-3 2.7-3.3v.2c0 .5.4.8.8.8.5 0 .9-.4.9-.8v-.3h1.6v.3c0 .4.4.8.8.8s.8-.4.8-.8v-.3h3.4c.3 1.2 1.2 2.2 2.5 2.6 1.2.3 2.5 0 3.4-.9.5-.5.8-1 .9-1.7H27c1.9 0 3.4 1.5 3.4 3.4v3.1h-6.2c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5h6.2V24c0 1.9-1.5 3.4-3.4 3.4zm3.4-8.2h-6.2c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9h6.2v3.8zm0 0"></path>
														<path d="M5.2 17.6c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.4-.4-.8-.8-.8zm0-4.3c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.4-.8-.8-.8zm0-4.4c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8V9.8c0-.5-.4-.9-.8-.9zm0 13.1c-.4 0-.8.4-.8.8V25c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.4-.8-.8-.8zM8.4 8.9c-.4 0-.8.4-.8.9V12c0 .4.4.8.8.8s.8-.4.8-.8V9.8c0-.5-.3-.9-.8-.9zm0 13.1c-.4 0-.8.4-.8.8V25c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.3-.8-.8-.8zm0-8.7c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.3-.8-.8-.8zm0 4.3c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.4-.3-.8-.8-.8zm0 0"></path>
													</svg>
												</span>
												{userAddress.slice(0, 6) +
													"....." +
													userAddress.slice(userAddress.length - 5, userAddress.length)}
											</button>
										)}
									</li>
								</ul>
							</nav>
						</div>

						<nav class="navbar navbar-light bg-faded">
							<div class="container-fluid">
								<button
									aria-label="Toggle navigation"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#navbarSupportedContent"
									class="no-collapsed navbar-toggler">
									<span class="line1"></span>
									<span class="line2"></span>
									<span class="line3"></span>
								</button>
								<div class="collapse navbar-collapse" id="navbarSupportedContent">
									<div class="d-md-none flex-column d-flex align-items-center gap-5">
										<Link to="/" class="nav-links">
											HOME
										</Link>
										<Link to="/mint" class="nav-links ">
											MINT
										</Link>
										{/* <Link to="/stake" class="nav-links">
											STAKE
										</Link> */}
										<ul class="navigation-list">
											<li>
												{!userAddress && (
													<button type="button" class="webapp btn btn-secondary" onClick={() => openModal()}>
														<span class="size-24">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
																<path d="M27 5.7h-7.6C19 4.1 17.6 3 16 3c-1.6 0-3 1.1-3.4 2.7H5c-2.8 0-5 2.2-5 5V24c0 2.8 2.2 5 5 5h22c2.8 0 5-2.2 5-5V10.7c0-2.8-2.2-5-5-5zM16 4.6c1 0 1.9.8 1.9 1.9 0 .5-.2 1-.5 1.3-.7.7-1.9.7-2.7 0-.3-.3-.5-.8-.5-1.3-.1-1.1.8-1.9 1.8-1.9zm11 22.8H9.2v-.3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.3H6v-.3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.2C2.8 27 1.6 25.6 1.6 24V10.7c0-1.6 1.1-3 2.7-3.3v.2c0 .5.4.8.8.8.5 0 .9-.4.9-.8v-.3h1.6v.3c0 .4.4.8.8.8s.8-.4.8-.8v-.3h3.4c.3 1.2 1.2 2.2 2.5 2.6 1.2.3 2.5 0 3.4-.9.5-.5.8-1 .9-1.7H27c1.9 0 3.4 1.5 3.4 3.4v3.1h-6.2c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5h6.2V24c0 1.9-1.5 3.4-3.4 3.4zm3.4-8.2h-6.2c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9h6.2v3.8zm0 0"></path>
																<path d="M5.2 17.6c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.4-.4-.8-.8-.8zm0-4.3c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.4-.8-.8-.8zm0-4.4c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8V9.8c0-.5-.4-.9-.8-.9zm0 13.1c-.4 0-.8.4-.8.8V25c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.4-.8-.8-.8zM8.4 8.9c-.4 0-.8.4-.8.9V12c0 .4.4.8.8.8s.8-.4.8-.8V9.8c0-.5-.3-.9-.8-.9zm0 13.1c-.4 0-.8.4-.8.8V25c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.3-.8-.8-.8zm0-8.7c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.3-.8-.8-.8zm0 4.3c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.4-.3-.8-.8-.8zm0 0"></path>
															</svg>
														</span>
														Connect Wallet
													</button>
												)}
												{userAddress && (
													<button type="button" class="webapp btn btn-secondary" onClick={() => logout()}>
														<span class="size-24">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
																<path d="M27 5.7h-7.6C19 4.1 17.6 3 16 3c-1.6 0-3 1.1-3.4 2.7H5c-2.8 0-5 2.2-5 5V24c0 2.8 2.2 5 5 5h22c2.8 0 5-2.2 5-5V10.7c0-2.8-2.2-5-5-5zM16 4.6c1 0 1.9.8 1.9 1.9 0 .5-.2 1-.5 1.3-.7.7-1.9.7-2.7 0-.3-.3-.5-.8-.5-1.3-.1-1.1.8-1.9 1.8-1.9zm11 22.8H9.2v-.3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.3H6v-.3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v.2C2.8 27 1.6 25.6 1.6 24V10.7c0-1.6 1.1-3 2.7-3.3v.2c0 .5.4.8.8.8.5 0 .9-.4.9-.8v-.3h1.6v.3c0 .4.4.8.8.8s.8-.4.8-.8v-.3h3.4c.3 1.2 1.2 2.2 2.5 2.6 1.2.3 2.5 0 3.4-.9.5-.5.8-1 .9-1.7H27c1.9 0 3.4 1.5 3.4 3.4v3.1h-6.2c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5h6.2V24c0 1.9-1.5 3.4-3.4 3.4zm3.4-8.2h-6.2c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9h6.2v3.8zm0 0"></path>
																<path d="M5.2 17.6c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.4-.4-.8-.8-.8zm0-4.3c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.4-.8-.8-.8zm0-4.4c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8V9.8c0-.5-.4-.9-.8-.9zm0 13.1c-.4 0-.8.4-.8.8V25c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.4-.8-.8-.8zM8.4 8.9c-.4 0-.8.4-.8.9V12c0 .4.4.8.8.8s.8-.4.8-.8V9.8c0-.5-.3-.9-.8-.9zm0 13.1c-.4 0-.8.4-.8.8V25c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.3-.8-.8-.8zm0-8.7c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.5-.3-.8-.8-.8zm0 4.3c-.4 0-.8.4-.8.8v2.2c0 .4.4.8.8.8s.8-.4.8-.8v-2.2c0-.4-.3-.8-.8-.8zm0 0"></path>
															</svg>
														</span>
														{userAddress.slice(0, 6) +
															"....." +
															userAddress.slice(userAddress.length - 5, userAddress.length)}
													</button>
												)}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</header>

			<nav>
				<Modal
					show={show1}
					style={{ opacity: 1 }}
					animation={false}
					onHide={closeModal}
					dialogClassName="metaModal"
					centered>
					<div class="modal-header">
						<div class="modal-title h4">Connect Wallet</div>
						<button type="button" class="btn-close" aria-label="Close" onClick={() => closeModal()}></button>
					</div>
					<div class="modal-body p-0">
						<div className={`${disableSignup ? "disabled" : ""}`} onClick={() => metaMask()}>
							<div className="walletLogo">
								<img className={`${disableSignup ? "disabled" : ""}`} src={metamask} alt="" />
							</div>
							<div className="fs-24 fw-700 mb-4 text-center">MetaMask</div>
							<div className="fs-16 fw-400 mb-4 text-center">Connect to your MetaMask Wallet</div>
						</div>
						<hr />
						<div className={`${disableSignup ? "disabled" : ""}`} onClick={() => walletConnect()}>
							<div className="walletLogo">
								<img className={`${disableSignup ? "disabled" : ""}`} src={walletConnectLogo} alt="" />
							</div>
							<div className="fs-24 fw-700 mb-4 text-center">WalletConnect</div>
							<div className="fs-16 fw-400 mb-4 text-center">Connect using WalletConnect</div>
						</div>
					</div>
				</Modal>
			</nav>
		</>
	);
};

export default NavBar;
