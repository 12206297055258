import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import "./App.css";
import Home from "./components/Home/Home";
import Minting from "./components/Minting/Minting";
import Staking from "./components/Staking/Staking";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";

function App() {
	const [userAddress, setUserAddress] = useState("");
	const [connector, setConnector] = useState({});
	const [chain, setChain] = useState();
	const [show1, setShow1] = useState(false);
	const [disableSignup, setDisableSignup] = useState(false);

	return (
		<div>
			<div className="full-page">
				<div className="full-page-wrapper">
					<Router>
						<NavBar
							setUserAddress={setUserAddress}
							userAddress={userAddress}
							connector={connector}
							setConnector={setConnector}
							setChain={setChain}
							show1={show1}
							setShow1={setShow1}
							disableSignup={disableSignup}
							setDisableSignup={setDisableSignup}
						/>
						<Routes>
							<Route path="/" element={<Home userAddress={userAddress} connector={connector} chain={chain} />} />
							<Route
								path="/mint"
								element={
									<Minting
										userAddress={userAddress}
										connector={connector}
										chain={chain}
										show1={show1}
										setShow1={setShow1}
										disableSignup={disableSignup}
										setDisableSignup={setDisableSignup}
									/>
								}
							/>
							{/* <Route
								path="/stake"
								element={<Staking userAddress={userAddress} connector={connector} chain={chain} />}
							/> */}
						</Routes>
						<Footer />
					</Router>
				</div>
			</div>
		</div>
	);
}

export default App;
