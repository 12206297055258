import { useState, useEffect } from "react";
import logo from "../../assets/logo2.png";
import bug from "../../assets/images/icon-bug-bordered.svg";
import twitter from "../../assets/images/icon-twitter-bordered.svg";
import discord from "../../assets/images/icon-discord-bordered.svg";
import dIcon from "../../assets/D.png";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import {
	DOX_V2_CONTRACT_ADDRESS,
	DOX_V2_CONTRACT_ABI,
	DOX_V2_TOKEN_ADDRESS,
	DOX_V2_TOKEN_ABI,
	DECIMALS,
	HEX_CHAIN_ID,
	CHAIN_ID,
} from "../../constants/constants";
import Web3 from "web3";
import moment from "moment";
// let web3 = new Web3(window.ethereum || "https://data-seed-prebsc-1-s1.binance.org:8545/");  //TESTNET
let web3 = new Web3(window.ethereum || "https://bsc-dataseed.binance.org/");  //MAINNET

/* global BigInt */

const Home = ({ userAddress, connector, chain }) => {
	const [nodes, setNodes] = useState([]);
	const [maxNodes, setMaxNodes] = useState(0);
	const [soldNodes, setSoldNodes] = useState([]);
	const [fee, setFee] = useState(0);
	const [reward, setReward] = useState();
	const [goldFee, setGoldFee] = useState();
	const [platinumFee, setPlatinumFee] = useState();
	const [titaniumFee, setTitaniumFee] = useState();
	const [goldPrice, setGoldPrice] = useState();
	const [platinumPrice, setPlatinumPrice] = useState();
	const [titaniumPrice, setTitaniumPrice] = useState();
	const [goldRewardPerDay, setGoldRewardPerDay] = useState();
	const [platinumRewardPerDay, setPlatinumRewardPerDay] = useState();
	const [titaniumRewardPerDay, setTitaniumRewardPerDay] = useState();
	const [tenDaysTax, setTenDaysTax] = useState();
	const [twentyDaysTax, setTwentyDaysTax] = useState();
	const [thirtyDaysTax, setThirtyDaysTax] = useState();
	const [allowance, setAllowance] = useState(0);
	const [arr, setArr] = useState([]);
	const [formValues, setFormValues] = useState([{ name: "", type: 0 }]);
	const [isLoading, setIsLoading] = useState(false);

	let handleNameChange = (i, e) => {
		let newFormValues = [...formValues];
		newFormValues[i] = {
			name: e.target.value,
			type: formValues[i]?.type || 0,
		};
		setFormValues(newFormValues);
	};

	let handleTypeChange = (index, type) => {
		let newFormValues = [...formValues];
		newFormValues[index].type = type;
		setFormValues(newFormValues);
	};

	let removeFormFields = (i) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		setFormValues(newFormValues);
	};

	let checkValues = () => {
		var valueArr = formValues.map(function (item) {
			return item.name;
		});
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) != idx;
		});
		return isDuplicate;
	};

	const initializeContract = async (abi, address) => {
		let res = new web3.eth.Contract(abi, address);
		return res;
	};

	const initWeb3 = (provider) => {
		const web3 = new Web3(provider);

		web3.eth.extend({
			methods: [
				{
					name: "chainId",
					call: "eth_chainId",
					outputFormatter: web3.utils.hexToNumber,
				},
			],
			headers: [
				{
					name: "Access-Control-Allow-Origin",
					value: "https://rinkeby.infura.io/v3/key",
				},
			],
		});
		return web3;
	};

	const setFees = async () => {
		let contract_instance = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);

		let gold = await contract_instance.methods.getGoldNodeFee().call();
		gold = gold / DECIMALS;

		setGoldFee(gold);

		let platinum = await contract_instance.methods.getPlatinumNodeFee().call();
		platinum = platinum / DECIMALS;
		setPlatinumFee(platinum);

		let titanium = await contract_instance.methods.getTitaniumNodeFee().call();
		titanium = titanium / DECIMALS;
		setTitaniumFee(titanium);
	};

	//Checking Allowance for current user whether he can buy nodes or not
	const checkAllowance = async () => {
		let contract_instance = await initializeContract(DOX_V2_TOKEN_ABI, DOX_V2_TOKEN_ADDRESS);
		let data = await contract_instance.methods.allowance(userAddress, DOX_V2_CONTRACT_ADDRESS).call();
		setAllowance(data);
	};

	//Nodes that have been purchase so far
	const getAllNodes = async () => {
		let contract_instance = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);
		let data = await contract_instance.methods.getSoldNodes().call();
		setSoldNodes(data);
	};

	//Total fees to be payed
	const getTotalFees = async () => {
		let contract_instance = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);
		let data = await contract_instance.methods.getAllFees(userAddress).call();
		if (data != 0) {
			data = data / DECIMALS;
			setFee(data);
		} else {
			setFee(0);
		}
	};

	//Obtaining Nodes owned by user
	const getNodes = async () => {
		setIsLoading(true);
		let contract_instance = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);
		let data = await contract_instance.methods.getUserNodes(userAddress).call();
		let reward = 0;
		for (let i = 0; i < data.length; i++) {
			reward = reward + Number(data[i].pendingRewards);
		}
		if (reward > 0) {
			reward = reward / DECIMALS;
		}
		let sortedData = [...data].sort((a, b) => {
			if (Number(a[1]) < Number(b[1])) return -1;
			if (Number(a[1]) > Number(b[1])) return 1;
			return 0;
		});
		setNodes(sortedData);
		setReward(reward);
		setIsLoading(false);
	};

	//Getting max amount of nodes for each user
	const getMaxNodes = async () => {
		let contract_instance = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);
		let data = await contract_instance.methods.MAX_NODES_PER_USER().call();
		setMaxNodes(data);
	};

	//Fetching Fee of Each Tier
	const getPrices = async () => {
		let contract_instance = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);

		let gold = await contract_instance.methods.getGoldNodePrice().call();
		gold = gold / DECIMALS;
		setGoldPrice(gold);

		let platinum = await contract_instance.methods.getPlatinumNodePrice().call();
		platinum = platinum / DECIMALS;
		setPlatinumPrice(platinum);

		let titanium = await contract_instance.methods.getTitaniumNodePrice().call();
		titanium = titanium / DECIMALS;
		setTitaniumPrice(titanium);
	};

	//fetching per day rewards for all nodes
	const getPerDayRewards = async () => {
		let contract_instance = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);

		let gold = await contract_instance.methods.getPerDayGoldRewards().call();
		gold = gold / DECIMALS;
		setGoldRewardPerDay(gold);

		let platinum = await contract_instance.methods.getPerDayPlatinumRewards().call();
		platinum = platinum / DECIMALS;
		setPlatinumRewardPerDay(platinum);

		let titanium = await contract_instance.methods.getPerDayTitaniumRewards().call();
		titanium = titanium / DECIMALS;
		setTitaniumRewardPerDay(titanium);
	};

	//Fetching Taxes for All Tiers
	const getTaxes = async () => {
		let contract_instance = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);

		let tenDays = await contract_instance.methods.getTenDaysTax().call();
		setTenDaysTax(tenDays);

		let twentyDays = await contract_instance.methods.getTwentyDaysTax().call();
		setTwentyDaysTax(twentyDays);

		let thirtyDays = await contract_instance.methods.getThirtyDaysTax().call();
		setThirtyDaysTax(thirtyDays);
	};

	//Calculating Days Difference to Calculate Tax
	const calculateDifference = (d) => {
		var a = Date.now();
		var b = new Date(d * 1000 + 1000 * 60 * 60 * 24 * 30);   //DAYS
		// var b = d * 1000 + 1000 * 60 * 30;  //MINUTES
		let dif = b - a;
		dif = dif / (1000 * 60 * 60 * 24);  //DAYS
		// dif = dif / (1000 * 60);     //MINUTES
		if (dif <= 10) {
			return thirtyDaysTax;
		} else if (dif > 10 && dif <= 20) {
			return twentyDaysTax;
		} else if (dif > 20) {
			return tenDaysTax;
		}
	};

	//Calculate pending fees
	const isFeeDue = (time) => {
		// let a = time * 1000 + 1000 * 60 * 30;   //MINUTES
		let a = time * 1000 + 1000 * 60 * 60 * 24 * 30;    //DAYS
		if (a < Date.now()) {
			return false;
		} else {
			return true;
		}
	};

	const isNodeExpire = (time) => {
		var a = Date.now();
		// var b = new Date(time * 1000 + 1000 * 60 * 60);    //MINUTES
		var b = new Date(time * 1000 + 1000 * 60 * 60 * 24 * 60);   //DAYS
		let dif = b - a;
		// dif = dif / (1000 * 60 * 60);    //MINUTES
		dif = dif / (1000 * 60 * 60 * 24);    //DAYS
		if (dif > 0 && dif < 60) {
			return true;
		} else {
			return false;
		}
	};

	//Write -Functions

	const claimSingleNode = async (node) => {
		Swal.fire({
			title: "Claiming",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
		});
		try {
			let contract = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);

			if (localStorage.getItem("walletconnect")) {
				const web3 = initWeb3(connector);
				const method = contract.methods.claimOne(node);
				const encodedABI = await method.encodeABI();

				await web3.eth
					.sendTransaction({
						from: userAddress,
						to: DOX_V2_CONTRACT_ADDRESS,
						data: encodedABI,
					})
					.then((result) => {
						if (result) {
							update();
							Swal.close();
							Swal.fire({
								icon: "success",
								title: "Successfully claimed!",
							});
						}
					})
					.catch((error) => {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to claim!...",
						});
					});
			} else {
				let data = await contract.methods.claimOne(node).send({
					from: userAddress,
				});
				if (data) {
					update();
					Swal.close();
					Swal.fire({
						icon: "success",
						title: "Successfully claimed!",
					});
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to claim!...",
					});
				}
			}
		} catch (error) {
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to Something unknown happened!...",
			});
		}
	};

	const paySingleNodeFee = async (node) => {
		Swal.fire({
			title: "Claiming",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
		});
		try {
			let contract = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);

			if (localStorage.getItem("walletconnect")) {
				const web3 = initWeb3(connector);
				const method = contract.methods.payFeeForNode(node);
				const encodedABI = await method.encodeABI();

				await web3.eth
					.sendTransaction({
						from: userAddress,
						to: DOX_V2_CONTRACT_ADDRESS,
						data: encodedABI,
					})
					.then((result) => {
						update();
						if (result) {
							Swal.close();
							Swal.fire({
								icon: "success",
								title: "Successfully fee payed!",
							});
						}
					})
					.catch((error) => {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to pay fee!...",
						});
					});
			} else {
				let data = await contract.methods.payFeeForNode(node).send({
					from: userAddress,
				});
				if (data) {
					update();
					Swal.close();
					Swal.fire({
						icon: "success",
						title: "Successfully fee payed!",
					});
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to pay fee!...",
					});
				}
			}
		} catch (error) {
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to Something unknown happened!...",
			});
		}
	};

	const approveMax = async () => {
		Swal.fire({
			title: "Approving",
			html: "Please proceed your transaction through wallet!",
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});

		try {
			let contract = await initializeContract(DOX_V2_TOKEN_ABI, DOX_V2_TOKEN_ADDRESS);

			if (localStorage.getItem("walletconnect")) {
				const web3 = new Web3(connector);
				const method = contract.methods.approve(DOX_V2_CONTRACT_ADDRESS, BigInt(50000000000000 * DECIMALS));
				const encodedABI = await method.encodeABI();

				await web3.eth
					.sendTransaction({
						from: userAddress,
						to: DOX_V2_TOKEN_ADDRESS,
						data: encodedABI,
					})
					.then((result) => {
						update();
						if (result) {
							Swal.close();
							Swal.fire({
								icon: "success",
								title: "Successfully Approved!",
							});
						}
					})
					.catch((error) => {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to Approved!...",
						});
					});
			} else {
				let data = await contract.methods.approve(DOX_V2_CONTRACT_ADDRESS, BigInt(50000000000000 * DECIMALS)).send({
					from: userAddress,
				});
				if (data) {
					update();
					Swal.close();
					Swal.fire({
						icon: "success",
						title: "Success...",
					});
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Oops...",
					});
				}
			}
		} catch (err) {
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to Something unknown happened!...",
			});
		}
	};

	const claimAllReward = async () => {
		Swal.fire({
			title: "Claiming",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
		});
		try {
			let contract = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);

			if (localStorage.getItem("walletconnect")) {
				const web3 = initWeb3(connector);
				const method = contract.methods.claimAll();
				const encodedABI = await method.encodeABI();

				await web3.eth
					.sendTransaction({
						from: userAddress,
						to: DOX_V2_CONTRACT_ADDRESS,
						data: encodedABI,
					})
					.then((result) => {
						update();
						if (result) {
							Swal.close();
							Swal.fire({
								icon: "success",
								title: "Successfully Claimed!",
							});
						}
					})
					.catch((error) => {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to claim!...",
						});
					});
			} else {
				let data = await contract.methods.claimAll().send({
					from: userAddress,
				});
				if (data) {
					update();
					Swal.close();
					Swal.fire({
						icon: "success",
						title: "Successfully Claimed!",
					});
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to claim!...",
					});
				}
			}
		} catch (error) {
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to Something unknown happened!...",
			});
		}
	};

	const payForAllNodes = async () => {
		Swal.fire({
			title: "Claiming",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
		});
		try {
			let contract = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);

			if (localStorage.getItem("walletconnect")) {
				const web3 = initWeb3(connector);
				const method = contract.methods.payAllFee();
				const encodedABI = await method.encodeABI();

				await web3.eth
					.sendTransaction({
						from: userAddress,
						to: DOX_V2_CONTRACT_ADDRESS,
						data: encodedABI,
					})
					.then((result) => {
						update();
						if (result) {
							Swal.close();
							Swal.fire({
								icon: "success",
								title: "Successfully payed!",
							});
						}
					})
					.catch((error) => {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to pay fee!...",
						});
					});
			} else {
				let data = await contract.methods.payAllFee().send({
					from: userAddress,
				});
				if (data) {
					update();
					Swal.close();
					Swal.fire({
						icon: "success",
						title: "Successfully payed!",
					});
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to pay fee!...",
					});
				}
			}
		} catch (error) {
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to Something unknown happened!...",
			});
		}
	};

	const buy = async () => {
		Swal.fire({
			title: "Purchase in progress",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
		});
		try {
			let contract = await initializeContract(DOX_V2_CONTRACT_ABI, DOX_V2_CONTRACT_ADDRESS);
			let nameArr = formValues.map((a) => a.name);
			let valueArr = formValues.map((a) => a.type);

			if (localStorage.getItem("walletconnect")) {
				let method;
				const web3 = initWeb3(connector);
				if (formValues.length > 1) {
					method = contract.methods.buyBulkNodes(nameArr, valueArr);
				} else {
					method = contract.methods.buyNode(nameArr[0], valueArr[0]);
				}
				const encodedABI = await method.encodeABI();

				await web3.eth
					.sendTransaction({
						from: userAddress,
						to: DOX_V2_CONTRACT_ADDRESS,
						data: encodedABI,
					})
					.then((result) => {
						update();
						setFormValues([{ name: "", type: 0 }]);
						setArr([]);
						if (result) {
							Swal.close();
							Swal.fire({
								icon: "success",
								title: "Successfully purchased!",
							});
						}
					})
					.catch((error) => {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to buy node!...",
						});
					});
			} else {
				let data;
				if (formValues.length > 1) {
					data = await contract.methods.buyBulkNodes(nameArr, valueArr).send({
						from: userAddress,
					});
				} else {
					data = await contract.methods.buyNode(nameArr[0], valueArr[0]).send({
						from: userAddress,
					});
				}
				if (data) {
					update();
					setFormValues([{ name: "", type: 0 }]);
					setArr([]);
					Swal.close();
					Swal.fire({
						icon: "success",
						title: "Successfully purchased!",
					});
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Failed to purchase node!...",
					});
				}
			}
		} catch (error) {
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to Something unknown happened!...",
			});
		}
	};

	const deleteArr = (i) => {
		let temp = [...arr];
		temp.splice(i - 1, 1);
		setArr(temp);
	};

	const pushArrays = () => {
		let temp = [...arr];
		let index = temp.length;
		temp.push(<div class="w-100 d-flex align-items-start sm:flex-col mb-4 add-node-row" key={index}></div>);
		setArr(temp);
	};

	const update = () => {
		checkAllowance();
		getNodes();
		getTotalFees();
		getAllNodes();
		getMaxNodes();
		getPrices();
		getTaxes();
		setFees();
		getPerDayRewards();
	};

	useEffect(() => {
		if (chain === CHAIN_ID || chain === HEX_CHAIN_ID) {
			if (userAddress) {
				checkAllowance();
				getNodes();
				getTotalFees();
				getAllNodes();
				getMaxNodes();
				getPrices();
				getTaxes();
				setFees();
				getPerDayRewards();
			}
			{
				getAllNodes();
				getMaxNodes();
				getPrices();
				getTaxes();
				setFees();
				getPerDayRewards();
			}
		}

		if (!window.ethereum) {
			getAllNodes();
			getMaxNodes();
			getPrices();
			getTaxes();
			setFees();
			getPerDayRewards();
		}
	}, [userAddress, chain]);

	return (
		<>
			<section class="section section-sm pb-2">
				<div class="container">
					<div class="align-items-center mb-0 row">
						<div class="col-md-2 col-lg-2">
							<div class="w-full d-flex align-items-center flex-column mb-4">
								<h3 class="w-full text-center h3 mb-2">YOUR NODES</h3>
								<div class="w-full vc text-center box fw-black fs-50 fs-50-sm">
									{nodes?.length}/{maxNodes}
								</div>
							</div>
						</div>
						<div class="col-md-8 col-lg-8">
							<div class="align-items-center row">
								<div class="col-md-4 col-lg-4">
									<div class="w-full d-flex align-items-center flex-column mb-4">
										<h3 class="w-full text-center h3 mb-2">GOLD TIER</h3>
										<div class="w-full vc text-center box fw-black fs-50 fs-50-sm">{soldNodes[0] || 0}</div>
									</div>
								</div>
								<div class="col-md-4 col-lg-4">
									<div class="w-full d-flex align-items-center flex-column mb-4">
										<h3 class="w-full text-center h3 mb-2">PLATINUM TIER</h3>
										<div class="w-full vc text-center box fw-black fs-50 fs-50-sm">{soldNodes[1] || 0}</div>
									</div>
								</div>
								<div class="col-md-4 col-lg-4">
									<div class="w-full d-flex align-items-center flex-column mb-4">
										<h3 class="w-full text-center h3 mb-2">TITANIUM TIER</h3>
										<div class="w-full vc text-center box fw-black fs-50 fs-50-sm">{soldNodes[2] || 0}</div>
									</div>
								</div>
								{/* <div class="col-md-3 col-lg-3">
                  <div class="w-full d-flex align-items-center flex-column mb-4">
                    <h3 class="w-full text-center h3 mb-2">TIER FOUR</h3>
                    <div class="w-full vc text-center box fw-black fs-50 fs-50-sm">
                      32888
                    </div>
                  </div>
                </div> */}
							</div>
						</div>
						<div class="col-md-2 col-lg-2">
							<div class="w-full d-flex align-items-center flex-column mb-4">
								<h3 class="w-full text-center h3 mb-2">TOTAL CREDITS</h3>
								<div class="w-full vc text-center box bg-blue fw-black fs-50 fs-50-sm">0</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="section section-sm">
				<div class="container">
					<div class="align-items-start mb-5 row">
						<div class="col-lg-7">
							<h3 class="w-full text-left pl-4 mb-2">CREATE A NODE (100,000 $DOXV2)</h3>
							<div class="w-full card-small mb-4 mb-lg-0">
								<div class="w-full">
									<form class="d-flex w-100 d-flex align-center justify-content-between flex-column sm:align-start sm:flex-col">
										<div class="w-100 d-flex align-items-start dynamic-row">
											<div class="w-100 d-flex align-items-start flex-column sm:flex-col">
												<div class="w-100 d-flex align-items-start sm:flex-col mb-4 add-node-row" key={0}>
													<div class="w-60 pr-2">
														<input
															name="name"
															class="form-control"
															type="text"
															value={formValues[0].name}
															placeholder="Enter Node Name"
															onChange={(e) => handleNameChange(0, e)}
														/>
													</div>
													<div class="w-20 pr-2 sm:pr-2 sm:w-30">
														<div class="dropdown">
															<button
																id="dropdownMenuButton1"
																data-bs-toggle="dropdown"
																type="button"
																aria-haspopup="true"
																aria-expanded="false"
																class="button button-block dropdown-toggle btn">
																<span class="d-flex align-items-center mr-2">
																	<span class="size-28">
																		<img src={dIcon} alt="" />
																	</span>
																</span>
																{formValues[0]?.type === 2
																	? "TITANIUM"
																	: formValues[0]?.type === 1
																	? "PLATINUM"
																	: "GOLD"}{" "}
																TIER
															</button>
															<div
																tabindex="-1"
																role="menu"
																aria-hidden="true"
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton1">
																<button
																	type="button"
																	tabindex="0"
																	role="menuitem"
																	class="dropdown-item"
																	onClick={() => handleTypeChange(0, 0)}>
																	<span class="d-flex align-items-center mr-2">
																		<span class="size-28">
																			<img src={dIcon} alt="" />
																		</span>
																	</span>
																	GOLD TIER
																</button>
																<button
																	type="button"
																	tabindex="0"
																	role="menuitem"
																	class="dropdown-item"
																	onClick={() => handleTypeChange(0, 1)}>
																	<span class="d-flex align-items-center mr-2">
																		<span class="size-28">
																			<img src={dIcon} alt="" />
																		</span>
																	</span>
																	PLATINUM TIER
																</button>
																<button
																	type="button"
																	tabindex="0"
																	role="menuitem"
																	class="dropdown-item"
																	onClick={() => handleTypeChange(0, 2)}>
																	<span class="d-flex align-items-center mr-2">
																		<span class="size-28">
																			<img src={dIcon} alt="" />
																		</span>
																	</span>
																	TITANIUM TIER
																</button>
															</div>
														</div>
													</div>
													{allowance > 0 && (
														<div class="w-20 sm:w-30 sm:mmt-5">
															<button
																type="button"
																class="button button-block"
																disabled={!userAddress || checkValues() || formValues[0].name === ""}
																onClick={() => buy()}>
																<span class="size-28">
																	<img src={dIcon} alt="" />
																</span>
																Buy Node
															</button>
														</div>
													)}
													{allowance == 0 && (
														<div class="w-20 sm:w-30 sm:mmt-5">
															<button
																type="button"
																class="button button-block"
																disabled={!userAddress}
																onClick={() => approveMax()}>
																<span class="size-28">
																	<img src={dIcon} alt="" />
																</span>
																Approve $DOXV2
															</button>
														</div>
													)}
												</div>
												{arr.map((e, i) => (
													<>
														<div class="w-100 d-flex align-items-start sm:flex-col mb-4 add-node-row" key={i + 1}>
															<div class="w-60 pr-2">
																<input
																	name="name"
																	value={formValues[i + 1]?.name || ""}
																	class="form-control"
																	type="text"
																	placeholder="Enter Node Name"
																	onChange={(e) => handleNameChange(i + 1, e)}
																/>
															</div>
															<div class="w-20 pr-2 sm:pr-2 sm:w-30">
																<div class="dropdown">
																	<button
																		id="dropdownMenuButton1"
																		data-bs-toggle="dropdown"
																		type="button"
																		aria-haspopup="true"
																		aria-expanded="false"
																		class="button button-block dropdown-toggle btn">
																		<span class="d-flex align-items-center mr-2">
																			<span class="size-28">
																				<img src={dIcon} alt="" />
																			</span>
																		</span>
																		{formValues[i + 1]?.type === 2
																			? "TITANIUM"
																			: formValues[i + 1]?.type === 1
																			? "PLATINUM"
																			: "GOLD"}{" "}
																		TIER
																	</button>
																	<div
																		tabindex="-1"
																		role="menu"
																		aria-hidden="true"
																		class="dropdown-menu"
																		aria-labelledby="dropdownMenuButton1">
																		<button
																			type="button"
																			tabindex="0"
																			role="menuitem"
																			class="dropdown-item"
																			onClick={() => handleTypeChange(i + 1, 0)}>
																			<span class="d-flex align-items-center mr-2">
																				<span class="size-28">
																					<img src={dIcon} alt="" />
																				</span>
																			</span>
																			GOLD TIER
																		</button>
																		<button
																			type="button"
																			tabindex="0"
																			role="menuitem"
																			class="dropdown-item"
																			onClick={() => handleTypeChange(i + 1, 1)}>
																			<span class="d-flex align-items-center mr-2">
																				<span class="size-28">
																					<img src={dIcon} alt="" />
																				</span>
																			</span>
																			PLATINUM TIER
																		</button>
																		<button
																			type="button"
																			tabindex="0"
																			role="menuitem"
																			class="dropdown-item"
																			onClick={() => handleTypeChange(i + 1, 2)}>
																			<span class="d-flex align-items-center mr-2">
																				<span class="size-28">
																					<img src={dIcon} alt="" />
																				</span>
																			</span>
																			TITANIUM TIER
																		</button>
																	</div>
																</div>
															</div>
															<div class="w-20 mobile-row-delete">
																<div class="d-flex align-items-center h-52">
																	<button
																		type="button"
																		class="button button-remove bg-transparent"
																		title="Delete Row"
																		onClick={() => {
																			removeFormFields(i + 1);
																			deleteArr(i + 1);
																		}}>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="20"
																			height="20"
																			viewBox="0 0 24 24"
																			fill="none"
																			stroke="currentColor"
																			stroke-width="2"
																			stroke-linecap="round"
																			stroke-linejoin="round">
																			<polyline points="3 6 5 6 21 6"></polyline>
																			<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
																			<line x1="10" y1="11" x2="10" y2="17"></line>
																			<line x1="14" y1="11" x2="14" y2="17"></line>
																		</svg>
																	</button>
																</div>
															</div>
														</div>
													</>
												))}

												{/* <div class="w-100 d-flex align-items-start sm:flex-col mb-4 add-node-row">
                          <div class="w-60 pr-2">
                            <input
                              name=""
                              class="form-control"
                              type="text"
                              placeholder="Enter Node Name"
                              value=""
                            />
                            <span class="error-block">
                              Node name is required
                            </span>
                          </div>
                          <div class="w-20 pr-2 sm:pr-2 sm:w-30">
                            <div class="dropdown">
                              <button
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                type="button"
                                aria-expanded="false"
                                class="button button-block dropdown-toggle btn"
                              >
                                <span class="d-flex align-items-center mr-2">
                                  <span class="size-28">
                                    <img src={dIcon} alt="" />
                                  </span>
                                </span>
                                PLATINUM TIER
                              </button>
                              <div
                                aria-labelledby="dropdownMenuButton1"
                                tabindex="-1"
                                role="menu"
                                aria-hidden="true"
                                class="dropdown-menu"
                              >
                                <button
                                  type="button"
                                  tabindex="0"
                                  role="menuitem"
                                  class="dropdown-item"
                                >
                                  <span class="d-flex align-items-center mr-2">
                                    <span class="size-28">
                                      <img src={dIcon} alt="" />
                                    </span>
                                  </span>
                                  GOLD TIER
                                </button>
                                <button
                                  type="button"
                                  tabindex="0"
                                  role="menuitem"
                                  class="dropdown-item"
                                >
                                  <span class="d-flex align-items-center mr-2">
                                    <span class="size-28">
                                      <img src={dIcon} alt="" />
                                    </span>
                                  </span>
                                  PLATINUM TIER
                                </button>
                                <button
                                  type="button"
                                  tabindex="0"
                                  role="menuitem"
                                  class="dropdown-item"
                                >
                                  <span class="d-flex align-items-center mr-2">
                                    <span class="size-28">
                                      <img src={dIcon} alt="" />
                                    </span>
                                  </span>
                                  TITANIUM TIER
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="w-20 mobile-row-delete">
                            <div class="d-flex align-items-center h-52">
                              <button
                                type="button"
                                class="button button-remove bg-transparent"
                                title="Delete Row"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>*/}

												{checkValues() && <span class="error-block">Node names must be unique</span>}

												<div class="w-100 d-flex align-items-center mb-0">
													<button type="button" class="button button-add bg-transparent" onClick={() => pushArrays()}>
														+
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div class="col-md-12 col-lg-5">
							<div class="align-items-start row">
								<div class="col-lg-6">
									<div class="w-full d-flex align-items-start flex-column mt-4 mt-lg-0">
										<h3 class="w-full text-left pl-4 mb-2">UNCLAIMED $DOX</h3>
										<div class="w-full text-center box p-4 fw-black md:flex-col unclaimed-bonus">
											<div class="box-count w-full d-flex align-items-center justify-content-center flex-column mb-3">
												<h6 class="fw-regular mb-2">Current $DOXV2 Rewards</h6>
												<div class="w-full d-flex align-items-center justify-content-center">
													<span class="fs-30 fs-30-sm count">{reward || 0}</span>
													<span class="size-24 ml-2">
														<img src={dIcon} alt="" />
													</span>
												</div>
											</div>
											<div class="claim-button-info">
												<span
													data-balloon="The amount of $DOXV2 that is currently unclaimed by the user. Clicking 'Claim All' will claim the balance shown and add taxes/NFTs to the shown amount."
													data-balloon-pos="up"
													data-balloon-length="large"
													class="info-block">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round">
														<circle cx="12" cy="12" r="10"></circle>
														<line x1="12" y1="16" x2="12" y2="12"></line>
														<line x1="12" y1="8" x2="12.01" y2="8"></line>
													</svg>
												</span>
												<button
													type="button"
													class="button button-block button-info button-md button-rounded md:ml-0px md:nowrap"
													disabled={reward == 0 || !userAddress}
													onClick={() => claimAllReward()}>
													<span class="size-24">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
															<path d="M32 18.4 29.3 4.1c-.1-.5-.5-.9-1.1-.9H23c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h4.3l2.3 12.2h-9.3c-.5 0-1 .4-1.1 1l-.5 4.2h-5.8l-.4-4.1c-.1-.5-.5-1-1.1-1H2.3L4.6 5.3H9c.6 0 1.1-.5 1.1-1.1-.1-.5-.5-1-1.1-1H3.7c-.5 0-1 .4-1.1.9L0 18.4v11.5c0 .6.5 1.1 1.1 1.1H31c.6 0 1.1-.5 1.1-1.1V18.6c-.1-.1-.1-.2-.1-.2zm-2.1 10.4H2.1v-9.2h8.5l.3 3.2c.1 1.1 1 1.9 2.1 1.9h5.7c1.1 0 2-.8 2.1-1.9l.3-3.2h8.5v9.2zm0 0"></path>
															<path d="m9.9 9.8 5.3 5.3c.2.2.5.3.8.3.3 0 .6-.1.8-.3l5.3-5.3c.4-.4.4-1.1 0-1.5-.4-.4-1-.4-1.5 0l-3.5 3.5V2.1c0-.6-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1v9.7l-3.5-3.5c-.4-.4-1-.3-1.5.1-.4.4-.4 1 0 1.4zm0 0"></path>
														</svg>
													</span>
													Claim All
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="w-full d-flex align-items-center flex-column mt-4 mt-lg-0">
										<h3 class="w-full text-left pl-4 mb-2">MONTHLY FEES</h3>
										<div class="w-full text-center box p-4 fw-black md:flex-col unclaimed-bonus">
											<div class="box-count w-full d-flex align-items-center justify-content-center flex-column mb-3">
												<h6 class="fw-regular mb-2">Total Monthly Fees</h6>
												<div class="w-full d-flex align-items-center justify-content-center">
													<span class="fs-30 fs-30-sm count">{fee} $DOXV2</span>
												</div>
											</div>
											<div class="claim-button-info">
												<span
													data-balloon="Clicking 'Pay All' will pay all of your monthly fees for all of your nodes. This is important to pay as it keeps your nodes producing $DOXV2."
													data-balloon-pos="left"
													data-balloon-length="large"
													class="info-block">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round">
														<circle cx="12" cy="12" r="10"></circle>
														<line x1="12" y1="16" x2="12" y2="12"></line>
														<line x1="12" y1="8" x2="12.01" y2="8"></line>
													</svg>
												</span>
												<button
													type="button"
													class="button button-block button-info button-md button-rounded md:ml-0px md:nowrap"
													disabled={fee == 0 || !userAddress}
													onClick={() => payForAllNodes()}>
													<span class="size-24 bg-28">
														<img src={dIcon} alt="" />
													</span>
													Pay All
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-lg-12">
							<div class="d-flex align-items-center justify-content-start mb-2">
								<h3 class="text-left pl-4 mb-0 mr-2">NODES LIST</h3>
							</div>
							<div class="w-full box-rounded">
								<div class="table-responsive-xl">
									<table class="mb-0 table">
										<thead>
											<tr>
												<th className="w-6">
													<div class="d-flex align-items-center justify-content-start">
														<span># List</span>
														<span class="sort is-active ">▲</span>
													</div>
												</th>
												<th className="w-13">
													<div class="d-flex align-items-center justify-content-start">
														<span>Node Name</span>
														<span class="sort  ">▲</span>
													</div>
												</th>
												<th className="w-12">
													<div class="d-flex align-items-center justify-content-center">
														<span>Node Type</span>
														<span class="sort  ">▲</span>
													</div>
												</th>
												<th className="w-15">
													<div class="d-flex align-items-center justify-content-center">
														<span>Current Claim Tax</span>
													</div>
												</th>
												<th className="w-20">
													<div class="d-flex align-items-center justify-content-center">
														<span
															data-balloon="The Accrued Rewards is showing how much $DOXV2 has been earned on the node level."
															data-balloon-pos="down"
															data-balloon-length="large"
															class="mr-1 info-block">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="14"
																height="14"
																viewBox="0 0 24 24"
																fill="none"
																stroke="currentColor"
																stroke-width="2"
																stroke-linecap="round"
																stroke-linejoin="round">
																<circle cx="12" cy="12" r="10"></circle>
																<line x1="12" y1="16" x2="12" y2="12"></line>
																<line x1="12" y1="8" x2="12.01" y2="8"></line>
															</svg>
														</span>
														<span>Accrued Rewards</span>
													</div>
												</th>
												<th className="w-20">
													<div class="d-flex align-items-center justify-content-center">
														<span
															data-balloon="This allows you to pay for individual nodes at the node level. Maybe you only want to pay for a couple nodes for now."
															data-balloon-pos="down"
															data-balloon-length="large"
															class="mr-1 info-block">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="14"
																height="14"
																viewBox="0 0 24 24"
																fill="none"
																stroke="currentColor"
																stroke-width="2"
																stroke-linecap="round"
																stroke-linejoin="round">
																<circle cx="12" cy="12" r="10"></circle>
																<line x1="12" y1="16" x2="12" y2="12"></line>
																<line x1="12" y1="8" x2="12.01" y2="8"></line>
															</svg>
														</span>
														<span>Monthly Fee</span>
													</div>
												</th>
											</tr>
										</thead>
										{isLoading ? (
											<tbody>
												<tr>
													<td colSpan="6">
														<div className="text-center">
															<Spinner animation="border" role="status" className="text-white">
																<span className="visually-hidden">Loading...</span>
															</Spinner>
														</div>
													</td>
												</tr>
											</tbody>
										) : (
											<tbody>
												{nodes.map((node, index) => {
													return (
														<tr
															className={`${
																isNodeExpire(node?.lastFeePaidAt) && !node?.pendingRewards > 0 ? "disabled" : ""
															}`}>
															<td> {index + 1} </td>
															<td>{node?.name}</td>
															<td className="text-center">
																{node?.tier === "0"
																	? "GOLD"
																	: node?.tier === "1"
																	? "PLATINUM"
																	: node?.tier === "2"
																	? "TITANIUM"
																	: ""}
															</td>
															<td className="text-center"> {calculateDifference(node?.lastClaimedAt)}% </td>
															<td className="text-center">
																<div class="w-full d-flex gap-2 align-items-center justify-content-center">
																	<span>{node?.pendingRewards / DECIMALS} $DOXV2</span>
																	<span
																		class={`badge-xs badge-rounded badge bg-light text-dark ${
																			node?.pendingRewards > 0 ? "" : "disabled"
																		}`}
																		onClick={() => claimSingleNode(node?.name)}>
																		Claim
																	</span>
																</div>
															</td>
															<td className="text-center">
																<div class="w-full d-flex gap-2 align-items-center justify-content-center">
																	<span>
																		{node?.tier === "0"
																			? goldFee
																			: node?.tier === "1"
																			? platinumFee
																			: node?.tier === "2"
																			? titaniumFee
																			: ""}{" "}
																		$DOXV2
																	</span>
																	{isFeeDue(node.lastFeePaidAt) ? (
                                    //Days----------------------
																		<span class="badge-xs badge-rounded mr-2 badge bg-info">                                  
																			Exp in {moment(node?.lastFeePaidAt * 1000 + 1000 * 60 * 60 * 24 * 30).toNow(true)}
																		</span> 
                                    //Minutes-------------------
                                  //   <span class="badge-xs badge-rounded mr-2 badge bg-info">                                  
                                  //   Exp in {moment(node?.lastFeePaidAt * 1000 + 1000 * 60  * 30).toNow(true)}
                                  // </span> 
																	) : (
																		<span
																			class={`badge-xs badge-rounded mr-2 badge  ${
																				isNodeExpire(node?.lastFeePaidAt) ? "button-red" : "button-dead"
																			}`}>
																			{isNodeExpire(node?.lastFeePaidAt) ? "Expired" : "Dead"}
																		</span>
																	)}
																	<span
																		class={`badge-xs badge-rounded badge bg-light text-dark ${
																			// node?.lastFeePaidAt * 1000 +
																			// 1000 * 60 * 60 * 24 * 30
																			//    >
																			// Date.now()
																			isFeeDue(node.lastFeePaidAt)
																				? "disabled"
																				: !isNodeExpire(node?.lastFeePaidAt)
																				? "disabled"
																				: ""
																		}`}
																		onClick={() => paySingleNodeFee(node?.name)}>
																		Pay
																	</span>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										)}
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="node-tier node-tier-sm pt-3 mb-5">
				<div class="container">
					<div class="w-full text-center mb-5">
						<h1 class="text-white u-upper">Node Tiers</h1>
					</div>
					<div class="row justify-content-center">
						<div class="col-md-6 col-lg-4">
							<div class="w-full d-flex align-items-start node-tier-block node-tier-block-sm">
								<div class="tier-number">
									<span class="number">1</span>
									<img src={logo} alt="PLATINUM TIER" class="size-90" />
								</div>
								<div class="tier-details tier-details-sm">
									<h2 class="mb-0">
										GOLD <br className="d-block-md" /> TIER
									</h2>
									<p>Node</p>
									<div class="w-full d-flex flex-column mt-2">
										<span class="badge-xs badge-rounded mr-2 badge bg-info">Cost: {Math.round(goldPrice)} $DOXV2</span>
										<span class="badge-xs badge-rounded mr-2 badge bg-info">Earn: {goldRewardPerDay} $DOXV2</span>
										<span class="badge-xs badge-rounded mr-2 badge bg-info">Fee: {goldFee} $DOXV2</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-4">
							<div class="w-full d-flex align-items-start node-tier-block node-tier-block-sm last">
								<div class="tier-number">
									<span class="number">2</span>
									<img src={logo} alt="PLATINUM TIER" class="size-90" />
								</div>
								<div class="tier-details tier-details-sm">
									<h2 class="mb-0">PLATINUM TIER</h2>
									<p>Node</p>
									<div class="w-full d-flex flex-column mt-2">
										<span class="badge-xs badge-rounded mr-2 badge bg-info">
											Cost: {Math.round(platinumPrice)} $DOXV2
										</span>
										<span class="badge-xs badge-rounded mr-2 badge bg-info">Earn: {platinumRewardPerDay} $DOXV2</span>
										<span class="badge-xs badge-rounded mr-2 badge bg-info">Fee: {platinumFee} $DOXV2</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-4">
							<div class="w-full d-flex align-items-start node-tier-block node-tier-block-sm">
								<div class="tier-number">
									<span class="number">3</span>
									<img src={logo} alt="TITANIUM TIER" class="size-90" />
								</div>
								<div class="tier-details tier-details-sm">
									<h2 class="mb-0">TITANIUM TIER</h2>
									<p>Node</p>
									<div class="w-full d-flex flex-column mt-2">
										<span class="badge-xs badge-rounded mr-2 badge bg-info">
											Cost: {Math.round(titaniumPrice)} $DOXV2
										</span>
										<span class="badge-xs badge-rounded mr-2 badge bg-info">Earn: {titaniumRewardPerDay} $DOXV2</span>
										<span class="badge-xs badge-rounded mr-2 badge bg-info">Fee: {titaniumFee} $DOXV2</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			l{" "}
			<section class="what-new what-new-sm">
				<div class="container">
					<div class="w-full text-center mb-5">
						<h1 class="text-white u-upper">WHAT NOW?</h1>
					</div>
					<div class="row">
						<div class="col-lg-12">
							<div class="w-full d-flex align-items-center odd mb-5">
								<div class="w-75 what-new-wrap">
									<div class="w-full d-flex">
										<a href="javascript:void(0)" target="_blank" rel="noreferrer" class="icon-138">
											<img src={discord} alt="DISCORD" class="img-fluid" />
										</a>
										<div class="description">
											<h4 class="u-upper mb-1">Join thousands of members in our Discord server!</h4>
											<p class="mb-0">
												Discord is our preferred platform for communication, information sharing, suggestions, and
												general discussion. Here you'll also be able to take part in decisions for the continued
												improvement of the protocol.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="w-full d-flex align-items-center even mb-5">
								<div class="w-75 what-new-wrap">
									<div class="w-full d-flex">
										<a href="javascript:void(0)" target="_blank" rel="noreferrer" class="icon-138">
											<img src={twitter} alt="TWITTER" class="img-fluid" />
										</a>
										<div class="description">
											<h4 class="u-upper mb-1">Follow us on Twitter!</h4>
											<p class="mb-0">
												Become an ambassador for the community by sharing the rewards you earn with your friends and
												family. Everyone can play a part in the continued growth of our project!
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="w-full d-flex align-items-center odd mb-5">
								<div class="w-75 what-new-wrap">
									<div class="w-full d-flex">
										<figure class="icon-138">
											<img src={bug} alt="REPORT BUGS AND ISSUES" class="img-fluid" />
										</figure>
										<div class="description">
											<h4 class="mb-1">REPORT BUGS AND ISSUES</h4>
											<p class="mb-0">
												We want to ensure that our investors have a safe and bug free platform that they have faith in.
												After all, we are the investment vehicle you can trust. Period.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home;
