import React, { useState, useEffect } from "react";
import logo from "../../assets/logo2.png";
import gold from "../../assets/gold.png";
import silver from "../../assets/silver.png";
import Web3 from "web3";
import Swal from "sweetalert2";
import {
	DOX_GOLD_CONTRACT_ADDRESS,
	DOX_GOLD_CONTRACT_ABI,
	DOX_GOLD_STAKING_CONTRACT_ADDRESS,
	DOX_GOLD_STAKING_CONTRACT_ABI,
	DOX_SILVER_CONTRACT_ADDRESS,
	DOX_SILVER_CONTRACT_ABI,
	DOX_SILVER_STAKING_CONTRACT_ADDRESS,
	DOX_SILVER_STAKING_CONTRACT_ABI,
	DOX_BRONZE_CONTRACT_ADDRESS,
	DOX_BRONZE_CONTRACT_ABI,
	DOX_BRONZE_STAKING_CONTRACT_ADDRESS,
	DOX_BRONZE_STAKING_CONTRACT_ABI,
	DECIMALS,
} from "../../constants/constants";
let web3 = new Web3(window.ethereum || "https://data-seed-prebsc-1-s1.binance.org:8545/");

const Staking = ({ userAddress, connector, chain }) => {
	const [nfts, setNFTs] = useState({ gold: [], silver: [], bronze: [] });
	const [stakedNFTs, setStakedNFTs] = useState({ gold: [], silver: [], bronze: [] });
	const [rewards, setRewards] = useState({
		gold: { daily_unstaked: 0, total_staked: 0 },
		silver: { daily_unstaked: 0, total_staked: 0 },
		bronze: { daily_unstaked: 0, total_staked: 0 },
	});
	const [isApproved, setIsApproved] = useState({ gold: false, silver: false, bronze: false });

	const initWeb3 = (provider) => {
		const web3 = new Web3(provider);

		web3.eth.extend({
			methods: [
				{
					name: "chainId",
					call: "eth_chainId",
					outputFormatter: web3.utils.hexToNumber,
				},
			],
			headers: [
				{
					name: "Access-Control-Allow-Origin",
					value: "https://rinkeby.infura.io/v3/key",
				},
			],
		});
		return web3;
	};

	const initializeContract = async (abi, address) => {
		if (localStorage.getItem("walletconnect")) {
			const web3 = initWeb3(connector);
		}
		try {
			let res = new web3.eth.Contract(abi, address);
			return res;
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const getNFTs = async () => {
		try {
			if (userAddress) {
				// DOX_Gold_NFTs
				const contract_gold = await initializeContract(DOX_GOLD_CONTRACT_ABI, DOX_GOLD_CONTRACT_ADDRESS);
				let gold = await contract_gold.methods
					.tokensOfOwner(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return [];
					});
				gold = gold.map((nft) => Number(nft)).sort();

				// DOX_Silver_NFTs
				const contract_silver = await initializeContract(DOX_SILVER_CONTRACT_ABI, DOX_SILVER_CONTRACT_ADDRESS);
				let silver = await contract_silver.methods
					.tokensOfOwner(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return [];
					});
				silver = silver.map((nft) => Number(nft)).sort();

				// DOX_Bronze_NFTs
				const contract_bronze = await initializeContract(DOX_BRONZE_CONTRACT_ABI, DOX_BRONZE_CONTRACT_ADDRESS);
				let bronze = await contract_bronze.methods
					.tokensOfOwner(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return [];
					});
				bronze = bronze.map((nft) => Number(nft)).sort();

				// console.log(gold, "gold", silver, "silver", bronze, "bronze");

				// set all nfts state
				setNFTs({ gold, silver, bronze });
			}
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const getStakedNFTs = async () => {
		try {
			if (userAddress) {
				// DOX_Gold_NFTs
				const contract_gold = await initializeContract(
					DOX_GOLD_STAKING_CONTRACT_ABI,
					DOX_GOLD_STAKING_CONTRACT_ADDRESS
				);
				let gold = await contract_gold.methods
					.getStakedNFTs(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return [];
					});
				gold = gold.map((nft) => Number(nft)).sort();

				// DOX_Silver_NFTs
				const contract_silver = await initializeContract(
					DOX_SILVER_STAKING_CONTRACT_ABI,
					DOX_SILVER_STAKING_CONTRACT_ADDRESS
				);
				let silver = await contract_silver.methods
					.getStakedNFTs(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return [];
					});
				silver = silver.map((nft) => Number(nft)).sort();

				// DOX_Bronze_NFTs
				const contract_bronze = await initializeContract(
					DOX_BRONZE_STAKING_CONTRACT_ABI,
					DOX_BRONZE_STAKING_CONTRACT_ADDRESS
				);
				let bronze = await contract_bronze.methods
					.getStakedNFTs(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return [];
					});
				bronze = bronze.map((nft) => Number(nft)).sort();

				// console.log(gold, "goldStaked", silver, "silverStaked", bronze, "bronzeStaked");

				// set all nfts state
				setStakedNFTs({ gold, silver, bronze });
			}
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const getRewards = async () => {
		try {
			if (userAddress) {
				// DOX_Gold_NFTs_Rewards
				const contract_gold = await initializeContract(
					DOX_GOLD_STAKING_CONTRACT_ABI,
					DOX_GOLD_STAKING_CONTRACT_ADDRESS
				);
				// All Rewards
				let gold = await contract_gold.methods
					.getAllRewards(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return 0;
					});
				gold = gold / DECIMALS;
				// NFT Reward
				let goldNFT = await contract_gold.methods
					.GOLD_NFT_DAILY_REWARD()
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return 0;
					});
				goldNFT = goldNFT / DECIMALS;

				// DOX_Silver_NFTs_Rewards
				const contract_silver = await initializeContract(
					DOX_SILVER_STAKING_CONTRACT_ABI,
					DOX_SILVER_STAKING_CONTRACT_ADDRESS
				);
				// All Rewards
				let silver = await contract_silver.methods
					.getAllRewards(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return 0;
					});
				silver = silver / DECIMALS;
				// NFT Reward
				let silverNFT = await contract_silver.methods
					.SILVER_NFT_DAILY_REWARD()
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return 0;
					});
				silverNFT = silverNFT / DECIMALS;

				// DOX_Bronze_NFTs_Rewards
				const contract_bronze = await initializeContract(
					DOX_BRONZE_STAKING_CONTRACT_ABI,
					DOX_BRONZE_STAKING_CONTRACT_ADDRESS
				);
				// All Rewards
				let bronze = await contract_bronze.methods
					.getAllRewards(userAddress)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return 0;
					});
				bronze = bronze / DECIMALS;
				// NFT Reward
				let bronzeNFT = await contract_bronze.methods
					.BRONZE_NFT_DAILY_REWARD()
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return 0;
					});
				bronzeNFT = bronzeNFT / DECIMALS;

				// console.log(goldNFT, "goldRewards", silverNFT, "silverRewards", bronzeNFT, "bronzeRewards");

				// set all nfts state
				setRewards({
					gold: { daily_unstaked: goldNFT, total_staked: gold },
					silver: { daily_unstaked: silverNFT, total_staked: silver },
					bronze: { daily_unstaked: bronzeNFT, total_staked: bronze },
				});
			}
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const checkApproval = async () => {
		try {
			if (userAddress) {
				// DOX_Gold_NFTs
				const contract_gold = await initializeContract(DOX_GOLD_CONTRACT_ABI, DOX_GOLD_CONTRACT_ADDRESS);
				const isGoldApproved = await contract_gold.methods
					.isApprovedForAll(userAddress, DOX_GOLD_STAKING_CONTRACT_ADDRESS)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return;
					});

				// DOX_Silver_NFTs
				const contract_silver = await initializeContract(DOX_SILVER_CONTRACT_ABI, DOX_SILVER_CONTRACT_ADDRESS);
				const isSilverApproved = await contract_silver.methods
					.isApprovedForAll(userAddress, DOX_SILVER_STAKING_CONTRACT_ADDRESS)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return;
					});

				// DOX_Bronze_NFTs
				const contract_bronze = await initializeContract(DOX_BRONZE_CONTRACT_ABI, DOX_BRONZE_CONTRACT_ADDRESS);
				const isBronzeApproved = await contract_bronze.methods
					.isApprovedForAll(userAddress, DOX_BRONZE_STAKING_CONTRACT_ADDRESS)
					.call()
					.then((res) => res)
					.catch((err) => {
						console.log(err);
						return;
					});

				// console.log(isGoldApproved, "gold", isSilverApproved, "silver", isBronzeApproved, "bronze");

				// set all contracts states
				setIsApproved({ gold: isGoldApproved, silver: isSilverApproved, bronze: isBronzeApproved });
			}
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const approveNFT_WC = async (num) => {
		Swal.fire({
			title: "Approving NFTs...",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
			allowOutsideClick: false,
		});
		try {
			if (userAddress) {
				let method;
				const web3 = initWeb3(connector);
				if (num === 0) {
					const contract_instance = await initializeContract(DOX_GOLD_CONTRACT_ABI, DOX_GOLD_CONTRACT_ADDRESS);
					method = contract_instance.methods.setApprovalForAll(DOX_GOLD_STAKING_CONTRACT_ADDRESS, true);
					const encodedABI = await method.encodeABI();
					const goldApproved = await web3.eth
						.sendTransaction({
							from: userAddress,
							to: DOX_GOLD_CONTRACT_ADDRESS,
							data: encodedABI,
						})
						.then((res) => res)
						.catch((error) => {
							console.log(error);
							return;
						});

					console.log(goldApproved, "gold");
					if (goldApproved) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully approved!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to approve...!",
						});
						return;
					}
				} else if (num === 1) {
					const contract_instance = await initializeContract(DOX_SILVER_CONTRACT_ABI, DOX_SILVER_CONTRACT_ADDRESS);
					method = contract_instance.methods.setApprovalForAll(DOX_SILVER_STAKING_CONTRACT_ADDRESS, true);
					const encodedABI = await method.encodeABI();
					const silverApproved = await web3.eth
						.sendTransaction({
							from: userAddress,
							to: DOX_SILVER_CONTRACT_ADDRESS,
							data: encodedABI,
						})
						.then((res) => res)
						.catch((error) => {
							console.log(error);
							return;
						});

					console.log(silverApproved, "silver");
					if (silverApproved) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully approved!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to approve...!",
						});
						return;
					}
				} else if (num === 2) {
					const contract_instance = await initializeContract(DOX_BRONZE_CONTRACT_ABI, DOX_BRONZE_CONTRACT_ADDRESS);
					method = contract_instance.methods.setApprovalForAll(DOX_BRONZE_STAKING_CONTRACT_ADDRESS, true);
					const encodedABI = await method.encodeABI();
					const bronzeApproved = await web3.eth
						.sendTransaction({
							from: userAddress,
							to: DOX_BRONZE_CONTRACT_ADDRESS,
							data: encodedABI,
						})
						.then((res) => res)
						.catch((error) => {
							console.log(error);
							return;
						});

					console.log(bronzeApproved, "bronze");
					if (bronzeApproved) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully approved!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to approve...!",
						});
						return;
					}
				}
				checkApproval();
			} else {
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Please connect your wallet...!",
				});
				return;
			}
		} catch (error) {
			console.log(error);
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to approve...!",
			});
			return;
		}
	};

	const approveNFT = async (num) => {
		if (localStorage.getItem("walletconnect")) {
			await approveNFT_WC(num);
			return;
		} else {
			Swal.fire({
				title: "Approving NFTs...",
				html: "Please proceed your transaction through wallet!",
				didOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
			});
			try {
				if (userAddress) {
					if (num === 0) {
						// DOX_Gold_NFTs
						const contract_gold = await initializeContract(DOX_GOLD_CONTRACT_ABI, DOX_GOLD_CONTRACT_ADDRESS);
						const goldApproved = await contract_gold.methods
							.setApprovalForAll(DOX_GOLD_STAKING_CONTRACT_ADDRESS, true)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(goldApproved, "gold");
						if (goldApproved) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully approved!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to approve...!",
							});
							return;
						}
					} else if (num === 1) {
						// DOX_Silver_NFTs
						const contract_silver = await initializeContract(DOX_SILVER_CONTRACT_ABI, DOX_SILVER_CONTRACT_ADDRESS);
						const silverApproved = await contract_silver.methods
							.setApprovalForAll(DOX_SILVER_STAKING_CONTRACT_ADDRESS, true)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(silverApproved, "silver");
						if (silverApproved) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully approved!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to approve...!",
							});
							return;
						}
					} else if (num === 2) {
						// DOX_Bronze_NFTs
						const contract_bronze = await initializeContract(DOX_BRONZE_CONTRACT_ABI, DOX_BRONZE_CONTRACT_ADDRESS);
						const bronzeApproved = await contract_bronze.methods
							.setApprovalForAll(DOX_BRONZE_STAKING_CONTRACT_ADDRESS, true)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(bronzeApproved, "bronze");
						if (bronzeApproved) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully approved!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to approve...!",
							});
							return;
						}
					}
					checkApproval();
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Please connect your wallet...!",
					});
					return;
				}
			} catch (error) {
				console.log(error);
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Failed to approve...!",
				});
				return;
			}
		}
	};

	const stakeNFTs_WC = async (nfts, num) => {
		Swal.fire({
			title: "Staking NFTs...",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
			allowOutsideClick: false,
		});
		try {
			if (userAddress) {
				const web3 = initWeb3(connector);
				if (num === 0) {
					// DOX_Gold_NFTs
					const contract_gold = await initializeContract(
						DOX_GOLD_STAKING_CONTRACT_ABI,
						DOX_GOLD_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_gold.methods.stake(nfts);
					const encodedABI = await method.encodeABI();
					const gold = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_GOLD_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(gold, "goldBeingStaked");
					if (gold) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully staked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to stake...!",
						});
						return;
					}
				} else if (num === 1) {
					// DOX_Silver_NFTs
					const contract_silver = await initializeContract(
						DOX_SILVER_STAKING_CONTRACT_ABI,
						DOX_SILVER_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_silver.methods.stake(nfts);
					const encodedABI = await method.encodeABI();
					const silver = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_SILVER_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(silver, "silverBeingStaked");
					if (silver) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully staked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to stake...!",
						});
						return;
					}
				} else if (num === 2) {
					// DOX_Bronze_NFTs
					const contract_bronze = await initializeContract(
						DOX_BRONZE_STAKING_CONTRACT_ABI,
						DOX_BRONZE_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_bronze.methods.stake(nfts);
					const encodedABI = await method.encodeABI();
					const bronze = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_BRONZE_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(bronze, "bronzeBeingStaked");
					if (bronze) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully staked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to stake...!",
						});
						return;
					}
				}
				getNFTs();
				getStakedNFTs();
				getRewards();
			} else {
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Please connect your wallet...!",
				});
				return;
			}
		} catch (error) {
			console.log(error);
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to stake...!",
			});
			return;
		}
	};

	const stakeNFTs = async (nfts, num) => {
		if (localStorage.getItem("walletconnect")) {
			console.log("calling from walletconnect");
			await stakeNFTs_WC(nfts, num);
			return;
		} else {
			Swal.fire({
				title: "Staking NFTs...",
				html: "Please proceed your transaction through wallet!",
				didOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
			});
			try {
				if (userAddress) {
					if (num === 0) {
						// DOX_Gold_NFTs
						const contract_gold = await initializeContract(
							DOX_GOLD_STAKING_CONTRACT_ABI,
							DOX_GOLD_STAKING_CONTRACT_ADDRESS
						);
						const gold = await contract_gold.methods
							.stake(nfts)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(gold, "goldBeingStaked");
						if (gold) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully staked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to stake...!",
							});
							return;
						}
					} else if (num === 1) {
						// DOX_Silver_NFTs
						const contract_silver = await initializeContract(
							DOX_SILVER_STAKING_CONTRACT_ABI,
							DOX_SILVER_STAKING_CONTRACT_ADDRESS
						);
						const silver = await contract_silver.methods
							.stake(nfts)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(silver, "silverBeingStaked");
						if (silver) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully staked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to stake...!",
							});
							return;
						}
					} else if (num === 2) {
						// DOX_Bronze_NFTs
						const contract_bronze = await initializeContract(
							DOX_BRONZE_STAKING_CONTRACT_ABI,
							DOX_BRONZE_STAKING_CONTRACT_ADDRESS
						);
						const bronze = await contract_bronze.methods
							.stake(nfts)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(bronze, "bronzeBeingStaked");
						if (bronze) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully staked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to stake...!",
							});
							return;
						}
					}
					getNFTs();
					getStakedNFTs();
					getRewards();
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Please connect your wallet...!",
					});
					return;
				}
			} catch (error) {
				console.log(error);
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Failed to stake...!",
				});
				return;
			}
		}
	};

	const unStakeNFT_WC = async (nft, num) => {
		Swal.fire({
			title: "Unstaking NFT...",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
			allowOutsideClick: false,
		});
		try {
			if (userAddress) {
				const web3 = initWeb3(connector);
				if (num === 0) {
					// DOX_Gold_NFTs
					const contract_gold = await initializeContract(
						DOX_GOLD_STAKING_CONTRACT_ABI,
						DOX_GOLD_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_gold.methods.unstakeOne(nft);
					const encodedABI = await method.encodeABI();
					const gold = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_GOLD_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(gold, "goldIsUnstaking");
					if (gold) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully unstaked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to unstake...!",
						});
						return;
					}
				} else if (num === 1) {
					// DOX_Silver_NFTs
					const contract_silver = await initializeContract(
						DOX_SILVER_STAKING_CONTRACT_ABI,
						DOX_SILVER_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_silver.methods.unstakeOne(nft);
					const encodedABI = await method.encodeABI();
					const silver = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_SILVER_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(silver, "silverIsUnstaking");
					if (silver) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully unstaked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to unstake...!",
						});
						return;
					}
				} else if (num === 2) {
					// DOX_Bronze_NFTs
					const contract_bronze = await initializeContract(
						DOX_BRONZE_STAKING_CONTRACT_ABI,
						DOX_BRONZE_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_bronze.methods.unstakeOne(nft);
					const encodedABI = await method.encodeABI();
					const bronze = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_BRONZE_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(bronze, "bronzeIsUnstaking");
					if (bronze) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully unstaked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to unstake...!",
						});
						return;
					}
				}
				getNFTs();
				getStakedNFTs();
			} else {
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Please connect your wallet...!",
				});
				return;
			}
		} catch (error) {
			console.log(error);
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to unstake...!",
			});
			return;
		}
	};

	const unStakeNFT = async (nft, num) => {
		if (localStorage.getItem("walletconnect")) {
			await unStakeNFT_WC(nft, num);
			return;
		} else {
			Swal.fire({
				title: "Unstaking NFT...",
				html: "Please proceed your transaction through wallet!",
				didOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
			});
			try {
				if (userAddress) {
					if (num === 0) {
						// DOX_Gold_NFTs
						const contract_gold = await initializeContract(
							DOX_GOLD_STAKING_CONTRACT_ABI,
							DOX_GOLD_STAKING_CONTRACT_ADDRESS
						);
						const gold = await contract_gold.methods
							.unstakeOne(nft)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(gold, "goldNftIsUnstaking");
						if (gold) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully unstaked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to unstake...!",
							});
							return;
						}
					} else if (num === 1) {
						// DOX_Silver_NFTs
						const contract_silver = await initializeContract(
							DOX_SILVER_STAKING_CONTRACT_ABI,
							DOX_SILVER_STAKING_CONTRACT_ADDRESS
						);
						const silver = await contract_silver.methods
							.unstakeOne(nft)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(silver, "silverNftIsUnstaking");
						if (silver) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully unstaked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to unstake...!",
							});
							return;
						}
					} else if (num === 2) {
						// DOX_Bronze_NFTs
						const contract_bronze = await initializeContract(
							DOX_BRONZE_STAKING_CONTRACT_ABI,
							DOX_BRONZE_STAKING_CONTRACT_ADDRESS
						);
						const bronze = await contract_bronze.methods
							.unstakeOne(nft)
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(bronze, "bronzeNftIsUnstaking");
						if (bronze) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully unstaked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to unstake...!",
							});
							return;
						}
					}
					getNFTs();
					getStakedNFTs();
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Please connect your wallet...!",
					});
					return;
				}
			} catch (error) {
				console.log(error);
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Failed to unstake...!",
				});
				return;
			}
		}
	};

	const unStakeNFTsAll_WC = async (num) => {
		Swal.fire({
			title: "Unstaking NFTs...",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
			allowOutsideClick: false,
		});
		try {
			if (userAddress) {
				const web3 = initWeb3(connector);
				if (num === 0) {
					// DOX_Gold_NFTs
					const contract_gold = await initializeContract(
						DOX_GOLD_STAKING_CONTRACT_ABI,
						DOX_GOLD_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_gold.methods.unstakeAll();
					const encodedABI = await method.encodeABI();
					const gold = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_GOLD_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(gold, "goldIsUnstaking");
					if (gold) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully unstaked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to unstake...!",
						});
						return;
					}
				} else if (num === 1) {
					// DOX_Silver_NFTs
					const contract_silver = await initializeContract(
						DOX_SILVER_STAKING_CONTRACT_ABI,
						DOX_SILVER_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_silver.methods.unstakeAll();
					const encodedABI = await method.encodeABI();
					const silver = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_SILVER_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(silver, "silverIsUnstaking");
					if (silver) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully unstaked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to unstake...!",
						});
						return;
					}
				} else if (num === 2) {
					// DOX_Bronze_NFTs
					const contract_bronze = await initializeContract(
						DOX_BRONZE_STAKING_CONTRACT_ABI,
						DOX_BRONZE_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_bronze.methods.unstakeAll();
					const encodedABI = await method.encodeABI();
					const bronze = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_BRONZE_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(bronze, "bronzeIsUnstaking");
					if (bronze) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully unstaked!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to unstake...!",
						});
						return;
					}
				}
				getNFTs();
				getStakedNFTs();
			} else {
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Please connect your wallet...!",
				});
				return;
			}
		} catch (error) {
			console.log(error);
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to unstake...!",
			});
			return;
		}
	};

	const unStakeNFTsAll = async (num) => {
		if (localStorage.getItem("walletconnect")) {
			await unStakeNFTsAll_WC(num);
			return;
		} else {
			Swal.fire({
				title: "Unstaking NFTs...",
				html: "Please proceed your transaction through wallet!",
				didOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
			});
			try {
				if (userAddress) {
					if (num === 0) {
						// DOX_Gold_NFTs
						const contract_gold = await initializeContract(
							DOX_GOLD_STAKING_CONTRACT_ABI,
							DOX_GOLD_STAKING_CONTRACT_ADDRESS
						);
						const gold = await contract_gold.methods
							.unstakeAll()
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(gold, "goldIsUnstaking");
						if (gold) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully unstaked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to unstake...!",
							});
							return;
						}
					} else if (num === 1) {
						// DOX_Silver_NFTs
						const contract_silver = await initializeContract(
							DOX_SILVER_STAKING_CONTRACT_ABI,
							DOX_SILVER_STAKING_CONTRACT_ADDRESS
						);
						const silver = await contract_silver.methods
							.unstakeAll()
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(silver, "silverIsUnstaking");
						if (silver) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully unstaked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to unstake...!",
							});
							return;
						}
					} else if (num === 2) {
						// DOX_Bronze_NFTs
						const contract_bronze = await initializeContract(
							DOX_BRONZE_STAKING_CONTRACT_ABI,
							DOX_BRONZE_STAKING_CONTRACT_ADDRESS
						);
						const bronze = await contract_bronze.methods
							.unstakeAll()
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(bronze, "bronzeIsUnstaking");
						if (bronze) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully unstaked!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to unstake...!",
							});
							return;
						}
					}
					getNFTs();
					getStakedNFTs();
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Please connect your wallet...!",
					});
					return;
				}
			} catch (error) {
				console.log(error);
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Failed to unstake...!",
				});
				return;
			}
		}
	};

	const claimReward_WC = async (num) => {
		Swal.fire({
			title: "Claiming Reward...",
			html: "Please proceed your transaction through wallet!",
			didOpen: () => {
				Swal.showLoading();
			},
			allowOutsideClick: false,
		});
		try {
			if (userAddress) {
				const web3 = initWeb3(connector);
				if (num === 0) {
					// DOX_Gold_NFTs
					const contract_gold = await initializeContract(
						DOX_GOLD_STAKING_CONTRACT_ABI,
						DOX_GOLD_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_gold.methods.claimAll();
					const encodedABI = await method.encodeABI();
					const gold = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_GOLD_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(gold, "claimingGold");
					if (gold) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully claimed!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to claim...!",
						});
						return;
					}
				} else if (num === 1) {
					// DOX_Silver_NFTs
					const contract_silver = await initializeContract(
						DOX_SILVER_STAKING_CONTRACT_ABI,
						DOX_SILVER_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_silver.methods.claimAll();
					const encodedABI = await method.encodeABI();
					const silver = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_SILVER_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(silver, "claimingSilver");
					if (silver) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully claimed!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to claim...!",
						});
						return;
					}
				} else if (num === 2) {
					// DOX_Bronze_NFTs
					const contract_bronze = await initializeContract(
						DOX_BRONZE_STAKING_CONTRACT_ABI,
						DOX_BRONZE_STAKING_CONTRACT_ADDRESS
					);
					const method = await contract_bronze.methods.claimAll();
					const encodedABI = await method.encodeABI();
					const bronze = await web3.eth
						.sendTransaction({ from: userAddress, to: DOX_BRONZE_STAKING_CONTRACT_ADDRESS, data: encodedABI })
						.then((res) => res)
						.catch((err) => {
							console.log(err);
							return;
						});

					console.log(bronze, "claimingBronze");
					if (bronze) {
						Swal.close();
						Swal.fire({
							icon: "Success",
							title: "Successfully claimed!",
						});
					} else {
						Swal.close();
						Swal.fire({
							icon: "error",
							title: "Failed to claim...!",
						});
						return;
					}
				}
				getRewards();
			} else {
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Please connect your wallet...!",
				});
				return;
			}
		} catch (error) {
			console.log(error);
			Swal.close();
			Swal.fire({
				icon: "error",
				title: "Failed to claim...!",
			});
			return;
		}
	};

	const claimReward = async (num) => {
		if (localStorage.getItem("walletconnect")) {
			await claimReward_WC(num);
			return;
		} else {
			Swal.fire({
				title: "Claiming Reward...",
				html: "Please proceed your transaction through wallet!",
				didOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
			});
			try {
				if (userAddress) {
					if (num === 0) {
						// DOX_Gold_NFTs
						const contract_gold = await initializeContract(
							DOX_GOLD_STAKING_CONTRACT_ABI,
							DOX_GOLD_STAKING_CONTRACT_ADDRESS
						);
						const gold = await contract_gold.methods
							.claimAll()
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(gold, "claimingGold");
						if (gold) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully claimed!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to claim...!",
							});
							return;
						}
					} else if (num === 1) {
						// DOX_Silver_NFTs
						const contract_silver = await initializeContract(
							DOX_SILVER_STAKING_CONTRACT_ABI,
							DOX_SILVER_STAKING_CONTRACT_ADDRESS
						);
						const silver = await contract_silver.methods
							.claimAll()
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(silver, "claimingSilver");
						if (silver) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully claimed!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to claim...!",
							});
							return;
						}
					} else if (num === 2) {
						// DOX_Bronze_NFTs
						const contract_bronze = await initializeContract(
							DOX_BRONZE_STAKING_CONTRACT_ABI,
							DOX_BRONZE_STAKING_CONTRACT_ADDRESS
						);
						const bronze = await contract_bronze.methods
							.claimAll()
							.send({ from: userAddress })
							.then((res) => res)
							.catch((err) => {
								console.log(err);
								return;
							});

						console.log(bronze, "claimingBronze");
						if (bronze) {
							Swal.close();
							Swal.fire({
								icon: "Success",
								title: "Successfully claimed!",
							});
						} else {
							Swal.close();
							Swal.fire({
								icon: "error",
								title: "Failed to claim...!",
							});
							return;
						}
					}
					getRewards();
				} else {
					Swal.close();
					Swal.fire({
						icon: "error",
						title: "Please connect your wallet...!",
					});
					return;
				}
			} catch (error) {
				console.log(error);
				Swal.close();
				Swal.fire({
					icon: "error",
					title: "Failed to claim...!",
				});
				return;
			}
		}
	};

	useEffect(() => {
		getNFTs();
		getStakedNFTs();
		getRewards();
		checkApproval();
	}, [userAddress]);

	return (
		<div>
			<section class="section section-sm">
				<div class="container">
					<div class="justify-content-center row">
						<div className="col-md-11">
							<div class="w-full text-center mb-5 d-md-flex align-items-center justify-content-between">
								<h2 class="text-white fw-black u-upper mb-0">My DOX NFTs</h2>
								{/* <div>
                  <span class="badge-xs badge-rounded mr-2 badge bg-info">
                    You Have 1500 Legendary Gems
                  </span>
                </div> */}
							</div>
						</div>
						<div class="col-sm-12 col-md-12 col-lg-11">
							<div class="row">
								<div class="col-md-12">
									<div class="booster-box">
										{userAddress ? (
											<>
												<div class="booster-box-wrap booster-box-wrap-sm text-center">
													<div className="row">
														<div className="col-md-6 mb-md-0 mb-4">
															<h6 class="text-white text-left mb-4">
																Stake your Gold DOX NFTs and receive daily rewards
															</h6>
															{nfts.gold.length > 0 ? (
																<>
																	{nfts.gold.map((nft, index) => {
																		return (
																			<div
																				key={index}
																				className="stake d-flex align-items-center justify-content-between mb-3">
																				<div class="tier-options">
																					<img src={gold} alt="GOLD" class="img-fluid" />
																				</div>
																				<h6 className="text-white mb-0">Gold #{nft}</h6>
																				<div class="d-flex align-items-center">
																					<h6 class="text-white text-center mb-0 pr-3">
																						{rewards.gold.daily_unstaked}$
																					</h6>
																					<button
																						disabled={!isApproved.gold}
																						type="button"
																						onClick={() => stakeNFTs([nft], 0)}
																						class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																						Stake
																					</button>
																				</div>
																			</div>
																		);
																	})}
																	<div class="w-full text-center d-md-flex align-items-center justify-content-between mb-4">
																		<h6 class="text-white mb-0">You will earn daily:</h6>
																		<h2 class="text-white mb-0">
																			{(nfts.gold.length * rewards.gold.daily_unstaked).toFixed(4) > 0
																				? (nfts.gold.length * rewards.gold.daily_unstaked).toFixed(4)
																				: 0}
																			$
																		</h2>
																	</div>
																	<div class="w-full text-center d-md-flex align-items-center justify-content-end">
																		{isApproved.gold ? (
																			<button
																				disabled={nfts.gold.length === 0}
																				type="button"
																				onClick={() => stakeNFTs(nfts.gold, 0)}
																				class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																				Stake All
																			</button>
																		) : (
																			<button
																				disabled={nfts.gold.length === 0}
																				type="button"
																				onClick={() => approveNFT(0)}
																				class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																				Approve All
																			</button>
																		)}
																	</div>
																</>
															) : (
																<div className="text-white">You don't have any unstaked NFT</div>
															)}
														</div>
														<div className="col-md-6 ">
															<h6 class="text-white text-left mb-4">Staked Gold DOX NFTs</h6>
															{stakedNFTs.gold.length > 0 ? (
																<>
																	{stakedNFTs.gold.map((nft, index) => {
																		return (
																			<div className="stake d-md-flex align-items-center justify-content-md-between justify-content-center mb-3">
																				<div className="d-md-block d-flex align-items-center justify-content-between">
																					<div class="tier-options">
																						<img src={gold} alt="GOLD" class="img-fluid" />
																					</div>
																					<div class=" d-md-none d-flex align-items-center gap-3">
																						<p class="text-white text-center mb-0">Staking in progresss</p>
																						<h6 class="text-white text-center mb-0 pr-3">
																							Gold #{stakedNFTs.gold[index]}
																						</h6>
																					</div>
																				</div>
																				<div class=" d-md-flex d-none align-items-center gap-3">
																					<p class="text-white text-center mb-0">Staking in progresss</p>
																					<h6 class="text-white text-center mb-0 pr-3">
																						Gold #{stakedNFTs.gold[index]}
																					</h6>
																				</div>
																				<div class="d-flex justify-content-center align-items-center">
																					<button
																						disabled=""
																						type="button"
																						onClick={() => unStakeNFT(nft, 0)}
																						class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																						Unstake
																					</button>
																				</div>
																			</div>
																		);
																	})}

																	<div class="w-full text-center mb-4 d-md-flex align-items-center justify-content-between">
																		<h6 class="text-white mb-0">You have earned:</h6>
																		<h2 class="text-white mb-0">{rewards.gold.total_staked}$</h2>
																	</div>
																	<div class="w-full text-center d-md-flex align-items-center justify-content-end">
																		<button
																			disabled={rewards.gold.total_staked <= 0}
																			type="button"
																			onClick={() => claimReward(0)}
																			class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																			Claim
																		</button>

																		{stakedNFTs.gold.length > 0 && (
																			<button
																				disabled=""
																				type="button"
																				onClick={() => unStakeNFTsAll(0)}
																				class="button button-info button-md fw-bold button-rounded ml-2 md:ml-0px md:nowrap">
																				Unstake All
																			</button>
																		)}
																	</div>
																</>
															) : (
																<div className="text-white">You don't have any staked NFT</div>
															)}
														</div>
													</div>
												</div>
												<div class="booster-box-wrap booster-box-wrap-sm text-center mt-4">
													<div className="row">
														<div className="col-md-6 mb-md-0 mb-4">
															<h6 class="text-white text-left mb-4">
																Stake your Silver DOX NFTs and receive daily rewards
															</h6>
															{nfts.silver.length > 0 ? (
																<>
																	{nfts.silver.map((nft, index) => {
																		return (
																			<div
																				key={index}
																				className="stake d-flex align-items-center justify-content-between mb-3">
																				<div class="tier-options">
																					<img src={silver} alt="Silver" class="img-fluid" />
																				</div>
																				<h6 className="text-white mb-0">Silver #{nft}</h6>
																				<div class="d-flex align-items-center">
																					<h6 class="text-white text-center mb-0 pr-3">
																						{rewards.silver.daily_unstaked}$
																					</h6>
																					<button
																						disabled={!isApproved.silver}
																						type="button"
																						onClick={() => stakeNFTs([nft], 1)}
																						class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																						Stake
																					</button>
																				</div>
																			</div>
																		);
																	})}
																	<div class="w-full text-center d-md-flex align-items-center justify-content-between mb-4">
																		<h6 class="text-white mb-0">You will earn daily:</h6>
																		<h2 class="text-white mb-0">
																			{(nfts.silver.length * rewards.silver.daily_unstaked).toFixed(4) > 0
																				? (nfts.silver.length * rewards.silver.daily_unstaked).toFixed(4)
																				: 0}
																			$
																		</h2>
																	</div>
																	<div class="w-full text-center d-md-flex align-items-center justify-content-end">
																		{isApproved.silver ? (
																			<button
																				disabled={nfts.silver.length === 0}
																				type="button"
																				onClick={() => stakeNFTs(nfts.silver, 1)}
																				class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																				Stake All
																			</button>
																		) : (
																			<button
																				disabled={nfts.silver.length === 0}
																				type="button"
																				onClick={() => approveNFT(1)}
																				class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																				Approve All
																			</button>
																		)}
																	</div>
																</>
															) : (
																<div className="text-white">You don't have any unstaked NFT</div>
															)}
														</div>
														<div className="col-md-6 ">
															<h6 class="text-white text-left mb-4">Staked Silver DOX NFTs</h6>
															{stakedNFTs.silver.length > 0 ? (
																<>
																	{stakedNFTs.silver.map((nft, index) => {
																		return (
																			<div className="stake d-md-flex align-items-center justify-content-md-between justify-content-center mb-3">
																				<div className="d-md-block d-flex align-items-center justify-content-between">
																					<div class="tier-options">
																						<img src={silver} alt="GOLD" class="img-fluid" />
																					</div>
																					<div class=" d-md-none d-flex align-items-center gap-3">
																						<p class="text-white text-center mb-0">Staking in progresss</p>
																						<h6 class="text-white text-center mb-0 pr-3">
																							Silver #{stakedNFTs.silver[index]}
																						</h6>
																					</div>
																				</div>
																				<div class=" d-md-flex d-none align-items-center gap-3">
																					<p class="text-white text-center mb-0">Staking in progresss</p>
																					<h6 class="text-white text-center mb-0 pr-3">
																						Silver #{stakedNFTs.silver[index]}
																					</h6>
																				</div>
																				<div class="d-flex justify-content-center align-items-center">
																					<button
																						disabled=""
																						type="button"
																						onClick={() => unStakeNFT(nft, 1)}
																						class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																						Unstake
																					</button>
																				</div>
																			</div>
																		);
																	})}

																	<div class="w-full text-center mb-4 d-md-flex align-items-center justify-content-between">
																		<h6 class="text-white mb-0">You have earned:</h6>
																		<h2 class="text-white mb-0">{rewards.silver.total_staked}$</h2>
																	</div>
																	<div class="w-full text-center d-md-flex align-items-center justify-content-end">
																		<button
																			disabled={rewards.silver.total_staked <= 0}
																			type="button"
																			onClick={() => claimReward(1)}
																			class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																			Claim
																		</button>

																		{stakedNFTs.silver.length > 0 && (
																			<button
																				disabled=""
																				type="button"
																				onClick={() => unStakeNFTsAll(1)}
																				class="button button-info button-md fw-bold button-rounded ml-2 md:ml-0px md:nowrap">
																				Unstake All
																			</button>
																		)}
																	</div>
																</>
															) : (
																<div className="text-white">You don't have any staked NFT</div>
															)}
														</div>
													</div>
												</div>
												<div class="booster-box-wrap booster-box-wrap-sm text-center mt-4">
													<div className="row">
														<div className="col-md-6 mb-md-0 mb-4">
															<h6 class="text-white text-left mb-4">
																Stake your Bronze DOX NFTs and receive daily rewards
															</h6>
															{nfts.bronze.length > 0 ? (
																<>
																	{nfts.bronze.map((nft, index) => {
																		return (
																			<div
																				key={index}
																				className="stake d-flex align-items-center justify-content-between mb-3">
																				<div class="tier-options">
																					<img src={logo} alt="Bronze" class="img-fluid" />
																				</div>
																				<h6 className="text-white mb-0"> Bronze #{nft}</h6>
																				<div class="d-flex align-items-center">
																					<h6 class="text-white text-center mb-0 pr-3">
																						{rewards.bronze.daily_unstaked}$
																					</h6>
																					<button
																						disabled={!isApproved.bronze}
																						type="button"
																						onClick={() => stakeNFTs([nft], 2)}
																						class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																						Stake
																					</button>
																				</div>
																			</div>
																		);
																	})}
																	<div class="w-full text-center d-md-flex align-items-center justify-content-between mb-4">
																		<h6 class="text-white mb-0">You will earn daily:</h6>
																		<h2 class="text-white mb-0">
																			{(nfts.bronze.length * rewards.bronze.daily_unstaked).toFixed(4) > 0
																				? (nfts.bronze.length * rewards.bronze.daily_unstaked).toFixed(4)
																				: 0}
																			$
																		</h2>
																	</div>
																	<div class="w-full text-center d-md-flex align-items-center justify-content-end">
																		{isApproved.bronze ? (
																			<button
																				disabled={nfts.bronze.length === 0}
																				type="button"
																				onClick={() => stakeNFTs(nfts.bronze, 2)}
																				class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																				Stake All
																			</button>
																		) : (
																			<button
																				disabled={nfts.bronze.length === 0}
																				type="button"
																				onClick={() => approveNFT(2)}
																				class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																				Approve All
																			</button>
																		)}
																	</div>
																</>
															) : (
																<div className="text-white">You don't have any unstaked NFT</div>
															)}
														</div>
														<div className="col-md-6 ">
															<h6 class="text-white text-left mb-4">Staked Bronze DOX NFTs</h6>
															{stakedNFTs.bronze.length > 0 ? (
																<>
																	{stakedNFTs.bronze.map((nft, index) => {
																		return (
																			<div className="stake d-md-flex align-items-center justify-content-md-between justify-content-center mb-3">
																				<div className="d-md-block d-flex align-items-center justify-content-between">
																					<div class="tier-options">
																						<img src={logo} alt="GOLD" class="img-fluid" />
																					</div>
																					<div class=" d-md-none d-flex align-items-center gap-3">
																						<p class="text-white text-center mb-0">Staking in progresss</p>
																						<h6 class="text-white text-center mb-0 pr-3">
																							Bronze #{stakedNFTs.bronze[index]}
																						</h6>
																					</div>
																				</div>
																				<div class=" d-md-flex d-none align-items-center gap-3">
																					<p class="text-white text-center mb-0">Staking in progresss</p>
																					<h6 class="text-white text-center mb-0 pr-3">
																						Bronze #{stakedNFTs.bronze[index]}
																					</h6>
																				</div>
																				<div class="d-flex justify-content-center align-items-center">
																					<button
																						disabled=""
																						type="button"
																						onClick={() => unStakeNFT(nft, 2)}
																						class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																						Unstake
																					</button>
																				</div>
																			</div>
																		);
																	})}

																	<div class="w-full text-center mb-4 d-md-flex align-items-center justify-content-between">
																		<h6 class="text-white mb-0">You have earned:</h6>
																		<h2 class="text-white mb-0">{rewards.bronze.total_staked}$</h2>
																	</div>
																	<div class="w-full text-center d-md-flex align-items-center justify-content-end">
																		<button
																			disabled={rewards.bronze.total_staked <= 0}
																			type="button"
																			onClick={() => claimReward(2)}
																			class="button button-info button-md fw-bold button-rounded md:ml-0px md:nowrap">
																			Claim
																		</button>

																		{stakedNFTs.bronze.length > 0 && (
																			<button
																				disabled=""
																				type="button"
																				onClick={() => unStakeNFTsAll(2)}
																				class="button button-info button-md fw-bold button-rounded ml-2 md:ml-0px md:nowrap">
																				Unstake All
																			</button>
																		)}
																	</div>
																</>
															) : (
																<div className="text-white">You don't have any staked NFT</div>
															)}
														</div>
													</div>
												</div>
											</>
										) : (
											<div class="booster-box-wrap booster-box-wrap-sm text-center">
												<div className="col-md-6 mb-md-0">
													<h6 class="text-white text-left mb-0">Please connect your wallet to see your NFTs</h6>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Staking;
