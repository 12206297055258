import React from 'react'
import logo from "../../assets/logo2.png"
import redit from "../../assets/images/icon-reddit.svg"
import discord from "../../assets/images/icon-discord.svg"
import twitter from "../../assets/images/icon-twitter.svg"
import telegram from "../../assets/images/telegram.svg"

const Footer = () => {
  return (
    <>
        <footer class="footer-wrap footer-wrap-sm">
            <div class="container">
                <div class="mb-4 sm:mmb-2 row">
                    <div class="col-lg-12">
                        <div class="w-full d-flex align-items-center justify-content-between footer-top position-relative">
                            <div class="footer-logo"><a href="javascript:void(0)" class="w-full"><img src={logo}
                                        alt="Cronodes" class="img-fluid"/></a></div>
                            <div class="footer-social">
                                <ul class="footer-social-lists">
                                    <li class="footer-social-item"><a href="https://discord.com/invite/EtXp9cVz79" target="_blank"
                                            class="img-fluid" rel="noreferrer"><img
                                                src={discord} alt="Discord"
                                                class="img-fluid"/><span class="sr-only">Discord</span></a></li>
                                    <li class="footer-social-item"><a href="https://twitter.com/DoxedOfficial_" target="_blank"
                                            class="" rel="noreferrer"><img src={twitter}
                                                alt="Twitter" class="img-fluid"/><span class="sr-only">Twitter</span></a></li>
                                    <li class="footer-social-item"><a href="https://t.me/Doxed_Token" target="_blank"
                                            class="" rel="noreferrer"><img src={telegram}
                                                alt="telegram" class="img-fluid"/><span class="sr-only">Telegram</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 sm:mmb-2 row">
                    <div class="col-lg-6">
                        <div class="footer-content">
                            <p>DOXV2 is a Defi as a Service (DaaS) fork. We have seen the <br class="d-none d-lg-block"/>
                                popularity and potential of these platforms and brought the <br class="d-none d-lg-block"/>
                                opportunity to the Binance Smart Chain, in a sustainable,<br class="d-none d-lg-block"/> transparent,
                                community driven approach.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        
                    </div>
                </div>
                <div class="w-full text-right md:text-center">© Copyright 2022 DOXV2. All rights reserved.</div>
            </div>
        </footer>
    </>
  )
}

export default Footer